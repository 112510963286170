import React, { createContext } from 'react';

export const ComponentPageContext = createContext({
  component: null,
  security: null,
  canEditProduct: false,
  isPreview: false,
  versions: [],
});

const defaultMapContextToProps = ({
  component,
  security,
  canEditProduct,
  isPreview,
  versions,
}) => ({
  component,
  security,
  canEditProduct,
  isPreview,
  versions,
});

export const withComponentPageContext = (
  mapContextToProps = defaultMapContextToProps,
) => WrappedComponent => props => (
  <ComponentPageContext.Consumer>
    {context => <WrappedComponent {...props} {...mapContextToProps(context)} />}
  </ComponentPageContext.Consumer>
);
