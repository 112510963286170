import { defineMessages } from 'react-intl';

export default defineMessages({
  LINKS_HINT: {
    id: 'pages.component.form.step.5.links',
    defaultMessage: 'Fill in the url to your Github repository, Documentation, Main url... and any useful link you would like to share.',
  },
  COMPONENT_HEADER_TITLE: {
    id: 'pages.component.form.step.5.header.title',
    defaultMessage: 'Additional links related to your component',
  },
  NAME_TITLE: {
    id: 'pages.component.form.step.5.links.name',
    defaultMessage: 'Name',
  },
  URL_TITLE: {
    id: 'pages.component.form.step.5.links.url',
    defaultMessage: 'URL',
  },
});
