import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import manifest from './subsets.manifest';
import messages from './subsets.messages';

const {
  fields: {
    subsets: { component: SubsetsField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const ComponentSubsetForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.SUBSET_TITLE} />}
        message={<FormattedMessage {...messages.SUBSET_HINT} />}
      />
      <SubsetsField />
    </FieldsGroup>
  </>
);

export default enhancer(ComponentSubsetForm);
