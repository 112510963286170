import { defineMessages } from 'react-intl';

export default defineMessages({
  ABB_TITLE: {
    id: 'pages.component.form.step.7.abb.title',
    defaultMessage: 'Architecture Building Blocks related to the component',
  },
  ABB_HINT: {
    id: 'pages.component.form.step.7.abb.hint',
    defaultMessage: 'Which ABBs are related to your component?',
  },
});
