import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';
import { getFormValues } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';

import { withComponentFormName } from 'react/business/components/form/Context';
import ComponentFormTeamForm from 'react/business/components/form/steps/step-3/TeamForm';
import ComponentFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withComponentFormName(),

  connect(
    (state, props) => ({
      isGlobal: getFormValues(props.formName)(state)?.isGlobal === 'true',
    }),
  ),

  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      COMPONENT_FORM_STEPS.FEATURES,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(
      COMPONENT_FORM_STEPS.BUSINESS_UNITS,
    ),
  }),

  memo,
);

const ComponentFormTeamStep = ({ onClickPreviousStep, onOpenPreview, onClickNextStep }) => (
  <ComponentFormStepLayout
    stepKey={COMPONENT_FORM_STEPS.TEAM}
    form={<ComponentFormTeamForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ComponentFormTeamStep.displayName = 'ComponentFormTeamStep';

ComponentFormTeamStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ComponentFormTeamStep.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ComponentFormTeamStep);
