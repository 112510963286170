import React, { memo } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import globalMessages from 'config/global.messages';

import omitProps from 'react/hoc/omitProps';
import { withComponentFormIsCurrentStep } from 'react/business/components/form/Context';

import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Button from 'react/generic/button/Button';
import IconButton from 'react/generic/button/IconButton';

import classNames from './step-layout.module.scss';
import { withParentProduct } from '../../../../business/components/form/form.utils';

const enhancer = compose(
  withComponentFormIsCurrentStep(props => props.stepKey),
  omitProps(['stepKey']),
  withParentProduct(),

  memo,
);

const ComponentFormStepLayout = ({
  form,
  className,
  onOpenPreview,
  onClickNextStep,
  onClickPreviousStep,
  isNextStepDisabled,
  isCurrentStep,
  parent,
  ...props
}) => {
  if (!parent) {
    return <Redirect to="/home" />;
  }
  return (
    <main
      {...props}
      className={cn(
        className,
        classNames.container,
        !isCurrentStep && classNames.hide,
      )}
    >
      <div className={classNames.formContainer}>
        <div className={classNames.form}>
          {form}
        </div>
      </div>

      {isCurrentStep
        && (onClickPreviousStep || onOpenPreview || onClickNextStep)
        && (
          <div className={classNames.buttonsContainer}>
            {onClickPreviousStep
              && (
                <Button
                  onClick={onClickPreviousStep}
                  className={classNames.previousButton}
                >
                  <Asset
                    name={ICON_MAP.chevron}
                    className={classNames.chevronLeft}
                  />
                  <FormattedMessage {...globalMessages.PREVIOUS} />
                </Button>
              )}

            {onOpenPreview
              && (
                <Tooltip title={<FormattedMessage {...globalMessages.PREVIEW} />}>
                  <IconButton
                    onClick={onOpenPreview}
                    overrideClassName={classNames.previewButton}
                    overrideClassNames={classNames}
                  >
                    <Asset
                      name={ICON_MAP.eye}
                      className={classNames.previewButtonIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}

            {onClickNextStep
              && (
                <Button
                  onClick={onClickNextStep}
                  className={classNames.nextButton}
                  disabled={isNextStepDisabled}
                >
                  <FormattedMessage {...globalMessages.NEXT} />
                  <Asset
                    name={ICON_MAP.chevron}
                    className={classNames.chevronRight}
                  />
                </Button>
              )}
          </div>
        )
      }
    </main>
  );
};

ComponentFormStepLayout.displayName = 'ComponentFormStepLayout';

ComponentFormStepLayout.propTypes = {
  form: PropTypes.node,
  onOpenPreview: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onClickPreviousStep: PropTypes.func,
  isNextStepDisabled: PropTypes.bool,
  isCurrentStep: PropTypes.bool,
  className: PropTypes.string,
};

ComponentFormStepLayout.defaultProps = {
  form: null,
  onOpenPreview: null,
  onClickNextStep: null,
  onClickPreviousStep: null,
  isNextStepDisabled: false,
  isCurrentStep: false,
  className: null,
};

export default enhancer(ComponentFormStepLayout);
