import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { versionShape } from 'shapes/version';

import VersionCard from '../card/VersionCard';

import messages from './version-card-list.messages';
import classNames from './version-card-list.module.scss';

const enhancer = compose(

  branch(
    props => !props.versions || props.versions?.length === 0,
    renderComponent(() => (
      <div className={classNames.container}>
        <div className={classNames.empty}>
          <FormattedMessage {...messages.EMPTY_VERSION_LIST} />
        </div>
      </div>
    )),
  ),

  memo,
);

const VersionCardList = ({ versions, componentId }) => (
  <div className={classNames.container}>
    <div className={classNames.wrapper}>
      {versions.map(version => (
        <VersionCard
          version={version}
          key={version.id}
          componentId={componentId}
        />
      ))}
    </div>
  </div>
);

VersionCardList.displayName = 'VersionCardList';

VersionCardList.propTypes = {
  versions: PropTypes.arrayOf(versionShape),
  componentId: PropTypes.string,
};

VersionCardList.defaultProps = {
  versions: [],
  componentId: '',
};

export default enhancer(VersionCardList);
