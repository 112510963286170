import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { selectSubsetsByIds } from '../../../../../redux/subsets/selectors';
import { getSubsetsByCommunityId } from '../../../../../redux/subsets/actions';

export default compose(
  connect(
    (state, props) => ({
      subsets: selectSubsetsByIds(state, props.subsetIds, props.communityId),
    }),
    {
      getSubsetsByCommunityId,
    },
  ),

  lifecycle({
    /**
     * Load subsets by ids.
     *
     * @async
     */
    async componentDidMount() {
      await this.props.getSubsetsByCommunityId(this.props.communityId);
    },
  }),
);
