import * as SecurityService from 'services/security';

import { ACTIONS } from './constants';

export const GET_SECURITY_DATA = entityId => ({
  type: ACTIONS.GET_SECURITY_DATA,
  entityId,
});

export const GET_SECURITY_DATA_SUCCESS = (entityId, result) => ({
  type: ACTIONS.GET_SECURITY_DATA_SUCCESS,
  entityId,
  result,
});

export const GET_SECURITY_DATA_FAILURE = (entityId, error) => ({
  type: ACTIONS.GET_SECURITY_DATA_FAILURE,
  entityId,
  error,
});

export const getSecurityData = entityId => async (dispatch) => {
  dispatch(GET_SECURITY_DATA(entityId));

  try {
    const result = await SecurityService.getSecurity(entityId);

    return dispatch(GET_SECURITY_DATA_SUCCESS(entityId, result));
  } catch (err) {
    return dispatch(GET_SECURITY_DATA_FAILURE(entityId, err));
  }
};

export const PATCH_SECURITY_DATA = (entityId, security) => ({
  type: ACTIONS.PATCH_SECURITY_DATA,
  entityId,
  security,
});

export const PATCH_SECURITY_DATA_SUCCESS = (entityId, security) => ({
  type: ACTIONS.PATCH_SECURITY_DATA_SUCCESS,
  entityId,
  security,
});

export const PATCH_SECURITY_DATA_FAILURE = (entityId, err) => ({
  type: ACTIONS.PATCH_SECURITY_DATA_FAILURE,
  entityId,
  err,
});

export const patchSecurityData = (entityId, security) => async (dispatch) => {
  dispatch(PATCH_SECURITY_DATA(entityId, security));

  try {
    const updatedSecurity = await SecurityService.patchSecurity(entityId, security);
    return dispatch(PATCH_SECURITY_DATA_SUCCESS(entityId, updatedSecurity));
  } catch (err) {
    return dispatch(PATCH_SECURITY_DATA_FAILURE(entityId, err));
  }
};
