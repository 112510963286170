import { difference } from 'lodash';
import {
  compose,
  lifecycle,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import { touch as touchAction, getFormValues } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';
import { COMPONENT_FORM_STEPS_MANIFESTS } from './manifests';
import { withComponentFormName } from '../Context';

const defaultGetInitialTouchedValues = () => [];

export default (
  getInitialTouchedValues = defaultGetInitialTouchedValues,
) => compose(
  // Get form name.
  withComponentFormName(),

  // Get all values from the form and touch action.
  connect(
    (state, props) => ({
      allValues: getFormValues(props.formName)(state),
    }),
    { touch: touchAction },
  ),

  // Override touch to include the form name.
  withHandlers({
    touch: ({ touch, formName }) => (...args) => touch(formName, ...args),
  }),

  withHandlers({
    touchStep: ({ touch, allValues }) => (step) => {
      // Get step manifest.
      const manifest = COMPONENT_FORM_STEPS_MANIFESTS[step];

      // Touch all fields in the step manifest.
      if (manifest) {
        manifest.touchFields(touch, allValues);
      }
    },
  }),

  // Touched steps state handler.
  withStateHandlers(
    () => ({
      touchedValues: getInitialTouchedValues(),
    }),
    {
      onTouch: ({ touchedValues }) => value => ({
        touchedValues: [...touchedValues, value],
      }),
    },
  ),

  /** When touching a step, touch all fields in the step. */
  lifecycle({
    /** Touch all fields of initially touched steps at mount. */
    componentDidMount() {
      this.props.touchedValues.forEach(this.props.touchStep);
    },

    /**
     * Touch all fields in newly touched steps.
     *
     * @param {object} prevProps - Prev props.
     */
    componentDidUpdate(prevProps) {
      const newTouchedSteps = difference(this.props.touchedValues, prevProps.touchedValues);
      newTouchedSteps.forEach(this.props.touchStep);
    },
  }),

  omitProps(['formName', 'allValues', 'touch', 'touchStep']),
);
