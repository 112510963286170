import { Manifest } from 'services/products/products-form';

import {
  subsets,
} from 'react/business/products/form/fields';

export default new Manifest({
  fields: {
    subsets,
  },
});
