import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { PRODUCT_LINKS, MAX_LINK_TITLE_LENGTH } from 'config/constants';
import linkMessages from 'react/business/products/links/product-link.messages';
import { linksReferences } from 'react/business/products/links/product-link.constants';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import globalMessages from 'config/global.messages';

import { LINKS_EXAMPLE } from './links.constants';
import manifest from './links.manifest';
import messages from './links.messages';
import rules from './links.validation-rules';

const {
  fields: {
    linksProductUrl: { component: LinksProductUrlField },
    linksProductMarketingUrl: { component: LinksProductMarketingUrlField },
    linksRoadmap: { component: LinksRoadmapField },
    linksDocumentation: { component: LinksDocumentationField },
    linksDiscussion: { component: LinksDiscussionField },
    linksApiReference: { component: LinksApiReferenceField },
    linksSources: { component: LinksSourcesField },
    linksBacklog: { component: LinksBacklogField },
    linksSupport: { component: LinksSupportField },
  },
} = manifest;

const makeTooltip = (tooltip, link) => (
  <div>
    {tooltip}
    <br />
    <br />
    <FormattedMessage {...globalMessages.EXAMPLE} />
    <br />
    {link}
  </div>
);

const linkInputs = [
  {
    linkName: PRODUCT_LINKS.URL,
    title: <FormattedMessage {...linkMessages.PRODUCT_URL} />,
    validate: rules.PRODUCT_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.URL].tooltip,
      LINKS_EXAMPLE.PRODUCT,
    ),
    showAddButton: false,
    reduxName: 'linksProductUrl',
    component: LinksProductUrlField,
  },
  {
    linkName: PRODUCT_LINKS.MARKETING,
    title: <FormattedMessage {...linkMessages.PRODUCT_MARKETING_URL} />,
    validate: rules.MARKETING_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.MARKETING].tooltip,
      LINKS_EXAMPLE.PRODUCT_MARKETING,
    ),
    reduxName: 'linksProductMarketingUrl',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksProductMarketingUrlField,
  },
  {
    linkName: PRODUCT_LINKS.ROADMAP,
    title: <FormattedMessage {...linkMessages.ROADMAP} />,
    validate: rules.ROADMAP_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.ROADMAP].tooltip,
      LINKS_EXAMPLE.ROADMAP,
    ),
    reduxName: 'linksRoadmap',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksRoadmapField,
  },
  {
    linkName: PRODUCT_LINKS.DOCUMENTATION,
    title: <FormattedMessage {...linkMessages.DOCUMENTATION} />,
    validate: rules.DOCUMENTATION_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.DOCUMENTATION].tooltip,
      LINKS_EXAMPLE.DOCUMENTATION,
    ),
    reduxName: 'linksDocumentation',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksDocumentationField,
  },
  {
    linkName: PRODUCT_LINKS.DISCUSSION,
    title: <FormattedMessage {...linkMessages.DISCUSSION} />,
    validate: rules.DISCUSSION_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.DISCUSSION].tooltip,
      LINKS_EXAMPLE.DISCUSSION,
    ),
    reduxName: 'linksDiscussion',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksDiscussionField,
  },
  {
    linkName: PRODUCT_LINKS.API_REFERENCE,
    title: <FormattedMessage {...linkMessages.API_REFERENCE} />,
    validate: rules.API_REFERENCE_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.API_REFERENCE].tooltip,
      LINKS_EXAMPLE.API_REFERENCE,
    ),
    reduxName: 'linksApiReference',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksApiReferenceField,
  },
  {
    linkName: PRODUCT_LINKS.SOURCE,
    title: <FormattedMessage {...linkMessages.SOURCE} />,
    validate: rules.SOURCE_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.SOURCE].tooltip,
      LINKS_EXAMPLE.SOURCE,
    ),
    reduxName: 'linksSources',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksSourcesField,
  },
  {
    linkName: PRODUCT_LINKS.BACKLOG,
    title: <FormattedMessage {...linkMessages.BACKLOG} />,
    validate: rules.BACKLOG_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.BACKLOG].tooltip,
      LINKS_EXAMPLE.BACKLOG,
    ),
    reduxName: 'linksBacklog',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksBacklogField,
  },
  {
    linkName: PRODUCT_LINKS.SUPPORT,
    title: <FormattedMessage {...linkMessages.SUPPORT} />,
    validate: rules.SUPPORT_URL,
    tooltip: makeTooltip(
      linksReferences[PRODUCT_LINKS.SUPPORT].tooltip,
      LINKS_EXAMPLE.SUPPORT,
    ),
    reduxName: 'linksSupport',
    showAddButton: true,
    maxLength: MAX_LINK_TITLE_LENGTH,
    component: LinksSupportField,
  },
];

const enhancer = compose(
  memo,
);

const ComponentFormLinksForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={(
          <FormattedMessage
            {... messages.COMPONENT_HEADER_TITLE}
          />
        )}
        message={<FormattedMessage {...messages.LINKS_HINT} />}
      />

      {linkInputs.map(linkInput => (linkInput.component
        && (
          <linkInput.component
            key={linkInput.linkName}
            linkName={linkInput.linkName}
            name={linkInput.reduxName}
            title={linkInput.title}
            showAddButton={linkInput.showAddButton}
            tooltip={linkInput.tooltip}
          />
        )
      ))
     }

    </FieldsGroup>
  </>
);

export default enhancer(ComponentFormLinksForm);
