import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from './subsets-list.module.scss';
import ProductSubsetItem from './listItem/ProductSubsetItem';
import { subsetsShape } from '../../../../shapes/subsets';
import withSubsets from './connect/withSubsets.connect';

const enhancer = compose(
  withSubsets,
  memo,
);

const ProductSubsetsList = ({ subsets }) => (
  <ul className={classNames.list}>
    {subsets.map(item => (
      <ProductSubsetItem
        item={item}
        key={item.id}
      />
    ))}
  </ul>
);

ProductSubsetsList.displayName = 'ProductSubsetsList';

ProductSubsetsList.propTypes = {
  subsets: PropTypes.arrayOf(subsetsShape),
};

ProductSubsetsList.defaultProps = {
  subsets: [],
};

export default enhancer(ProductSubsetsList);
