import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { createForm, formShape } from 'rc-form';

import { productShape } from 'shapes/product';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import messages from 'react/generic/form/validators/index.messages';
import { htmlToEditorState } from 'react/generic/form/wysiwyg/wysiwyg.utils';
import { securityShape } from 'shapes/security';

/**
 * Format ISO dates.
 *
 * @param {string} isoDate - Date.
 * @returns {string} - Date.
 */
function formatDate(isoDate) {
  const dateObj = new Date(isoDate);
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Les mois sont 0-indexés
  const year = dateObj.getUTCFullYear();

  return `${year}-${month}-${day}`;
}

export default (WrappedComponent) => {
  const enhancer = compose(
    createForm(),
    withHandlers(({ form, onSubmit }) => ({
      onSubmit: () => (event) => {
        event.preventDefault();
        event.stopPropagation();

        form.validateFields((err, values) => {
          if (!err) {
            onSubmit({ ...values });
          }
        });
      },
    })),

    memo,
  );

  const CreateProductAdditionalInfoFormContainer = ({
    form,
    product,
    security,
    onSubmit,
    ...props
  }) => (
    <WrappedComponent
      {...props}
      onSubmit={onSubmit}
      form={form}
      cidcDecorator={form.getFieldDecorator(
        'cidc',
        {
          initialValue: product.cid?.c?.toString() || null,
        },
      )}
      cidiDecorator={form.getFieldDecorator(
        'cidi',
        {
          initialValue: product.cid?.i?.toString() || null,
        },
      )}
      ciddDecorator={form.getFieldDecorator(
        'cidd',
        {
          initialValue: product.cid?.d?.toString() || null,
        },
      )}
      biaDecorator={form.getFieldDecorator(
        'bia',
        {
          initialValue: product.bia,
        },
      )}
      personalDataDecorator={form.getFieldDecorator(
        'personalData',
        {
          initialValue: product.personalData,
        },
      )}
      psiDecorator={form.getFieldDecorator(
        'psi',
        {
          initialValue: product.psi,
        },
      )}
      aliasesDecorator={form.getFieldDecorator(
        'aliases',
        {
          initialValue: product.aliases,
        },
      )}
      invoicingModelDecorator={form.getFieldDecorator(
        'invoicingModel',
        {
          initialValue: product.invoicingModel,
        },
      )}
      githubPrefixDecorator={form.getFieldDecorator(
        'githubPrefix',
        {
          initialValue: product.githubPrefix || '',
        },
      )}
      recoveryPointObjectiveRecommendedDecorator={form.getFieldDecorator(
        'recoveryPointObjectiveRecommended',
        {
          initialValue: product.recoveryPointObjectiveRecommended || null,
        },
      )}
      recoveryTimeObjectiveRecommendedDecorator={form.getFieldDecorator(
        'recoveryTimeObjectiveRecommended',
        {
          initialValue: product.recoveryTimeObjectiveRecommended || null,
        },
      )}
      supportTimeRangeStartDecorator={form.getFieldDecorator(
        'supportTimeRangeStart',
        {
          initialValue: product.supportTimeRange?.value ?
            moment.utc(product.supportTimeRange.value) : undefined,
        },
      )}
      supportTimeRangeEndDecorator={form.getFieldDecorator(
        'supportTimeRangeEnd',
        {
          // eslint-disable-next-line camelcase
          initialValue: product.supportTimeRange?.end_value ?
            moment.utc(product.supportTimeRange.end_value) : undefined,
        },
      )}
      supportDaysAvailableDecorator={form.getFieldDecorator(
        'supportDaysAvailable',
        {
          initialValue: product.supportDaysAvailable || [],
        },
      )}
      recoveryPointObjectiveDecorator={form.getFieldDecorator(
        'recoveryPointObjective',
        {
          initialValue: product.recoveryPointObjective || null,
        },
      )}
      recoveryTimeObjectiveDecorator={form.getFieldDecorator(
        'recoveryTimeObjective',
        {
          initialValue: product.recoveryTimeObjective || null,
        },
      )}
      scheduledMaintenanceRangeDecorator={form.getFieldDecorator(
        'scheduledMaintenanceRange',
        {
          initialValue: htmlToEditorState(product.scheduledMaintenanceRange || ''),
        },
      )}
      incidentTakingIntoAccountDecorator={form.getFieldDecorator(
        'incidentTakingIntoAccount',
        {
          initialValue: product.incidentTakingIntoAccount || '',
        },
      )}
      urgence1Decorator={form.getFieldDecorator(
        'urgence1',
        {
          initialValue: product.urgence1 || '',
        },
      )}
      urgence2Decorator={form.getFieldDecorator(
        'urgence2',
        {
          initialValue: product.urgence2 || '',
        },
      )}
      urgence3Decorator={form.getFieldDecorator(
        'urgence3',
        {
          initialValue: product.urgence3 || '',
        },
      )}
      takingIntoAccountRequestsDecorator={form.getFieldDecorator(
        'takingIntoAccountRequests',
        {
          initialValue: product.takingIntoAccountRequests || '',
        },
      )}
      requestedServicesDecorator={form.getFieldDecorator(
        'requestedServices',
        {
          initialValue: htmlToEditorState(product.requestedServices || ''),
        },
      )}
      linkToServiceCatalogUrlDecorator={form.getFieldDecorator(
        'linkToServiceCatalogUrl',
        {
          initialValue: product.linkToServiceCatalog?.uri || '',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (_, value) => !value || /^(http|https):\/\/(.+)\.(.+)/.test(value),
              message: (<FormattedMessage {...messages.EXTERNAL_URL} />),
            },
          ],
        },
      )}
      linkToServiceCatalogTitleDecorator={form.getFieldDecorator(
        'linkToServiceCatalogTitle',
        {
          initialValue: product.linkToServiceCatalog?.title || '',
        },
      )}
      availabilityDecorator={form.getFieldDecorator(
        'availability',
        {
          initialValue: product.availability || '',
        },
      )}
      availabilityInfDecorator={form.getFieldDecorator(
        'availabilityInf',
        {
          initialValue: product.availabilityInf || '',
        },
      )}
      availabilityInfoLinkUrlDecorator={form.getFieldDecorator(
        'availabilityInfoLinkUrl',
        {
          initialValue: product.availabilityInfoLink?.uri || '',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (_, value) => !value || /^(http|https):\/\/(.+)\.(.+)/.test(value),
              message: (<FormattedMessage {...messages.EXTERNAL_URL} />),
            },
          ],
        },
      )}
      availabilityInfoLinkTitleDecorator={form.getFieldDecorator(
        'availabilityInfoLinkTitle',
        {
          initialValue: product.availabilityInfoLink?.title || '',
        },
      )}
      latencyInfoDecorator={form.getFieldDecorator(
        'latencyInfo',
        {
          initialValue: product.latencyInfo || '',
        },
      )}
      latencyInfoLinkUrlDecorator={form.getFieldDecorator(
        'latencyInfoLinkUrl',
        {
          initialValue: product.latencyInfoLink?.uri || '',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (_, value) => !value || /^(http|https):\/\/(.+)\.(.+)/.test(value),
              message: (<FormattedMessage {...messages.EXTERNAL_URL} />),
            },
          ],
        },
      )}
      latencyInfoLinkTitleDecorator={form.getFieldDecorator(
        'latencyInfoLinkTitle',
        {
          initialValue: product.latencyInfoLink?.title || '',
        },
      )}
      uptimeInfoDecorator={form.getFieldDecorator(
        'uptimeInfo',
        {
          initialValue: product.uptimeInfo || '',
        },
      )}
      uptimeInfoLinkUrlDecorator={form.getFieldDecorator(
        'uptimeInfoLinkUrl',
        {
          initialValue: product.uptimeInfoLink?.uri || '',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (_, value) => !value || /^(http|https):\/\/(.+)\.(.+)/.test(value),
              message: (<FormattedMessage {...messages.EXTERNAL_URL} />),
            },
          ],
        },
      )}
      uptimeInfoLinkTitleDecorator={form.getFieldDecorator(
        'uptimeInfoLinkTitle',
        {
          initialValue: product.uptimeInfoLink?.title || '',
        },
      )}
      lastBIADateDecorator={form.getFieldDecorator(
        'lastBIADate',
        {
          initialValue: security?.lastBIADate ? formatDate(security.lastBIADate) : null,
        },
      )}
    />
  );

  CreateProductAdditionalInfoFormContainer.displayName = 'CreateProductAdditionalInfoFormContainer';

  CreateProductAdditionalInfoFormContainer.propTypes = {
    product: productShape.isRequired,
    security: securityShape,
    form: formShape.isRequired,
    onSubmit: PropTypes.func,
  };

  CreateProductAdditionalInfoFormContainer.defaultProps = {
    onSubmit: noop,
    security: undefined,
  };

  return enhancer(CreateProductAdditionalInfoFormContainer);
};
