import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import {
  getFormAsyncErrors,
  getFormInitialValues,
  getFormSyncErrors,
  getFormValues,
  hasSubmitFailed,
} from 'redux-form';
import { isEmpty, isEqual, noop } from 'lodash';
import { Tooltip } from 'antd';

import connect from 'react/hoc/connectProxy';
import ROUTES from 'react/routes';
import {
  selectDetailedProductsMap,
  selectIsCreateProductLoading,
  selectIsEditProductLoading,
} from 'redux/products/selectors';
import { FORM_MODES } from 'react/business/products/form/form.constants';
import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Button from 'react/generic/button/Button';
import withDialogState from 'react/generic/dialog/withDialogState';

import { fromFormToStore } from 'services/products/product-form.mapper';

import globalMessages from 'config/global.messages';
import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import { productShape } from 'shapes/product';
import { currentUserShape } from 'shapes/user';
import { validationStatuses } from 'redux/products/constants';
import {
  userIsBusinessUnitArchitect,
  userIsProductCommunityArchitect,
  userIsProductDomainLeader,
} from 'services/products/utils';
import { selectCommunitiesMap, selectDomainsMap } from 'redux/communities/selectors';
import { communityShape, domainShape } from 'shapes/community';
import withRouteParams from 'react/hoc/withRouteParams';
import { withComponentFormMode, withComponentFormName } from 'react/business/components/form/Context';
import { editProduct } from 'services/products';
import ComponentFormStepLayout from '../layout/StepLayout';
import messages from './component-form-validation.messages';
import classNames from './component-form-validation.module.scss';
import DialogLeave from './dialog/DialogLeave';
import withProductFromFormValues from '../withComponentFromFormValues';
import manifest from './validation.manifest';
import ValidatorPerson from './validator-person/ValidatorPerson';
import { businessUnitShape } from '../../../../../shapes/businessUnit';
import { selectBusinessUnitsMap } from '../../../../../redux/businessUnits/selectors';

const mapRouteParamsToProps = (params => ({
  componentId: params.componentId,
}));

const enhancer = compose(

  withRouter,
  withComponentFormName(),
  withComponentFormMode(),

  withDialogState('Leave'),
  withRouteParams(mapRouteParamsToProps),

  connect(
    (state, props) => {
      const component = selectDetailedProductsMap(state)[props.componentId];
      const domain = selectDomainsMap(state)[component?.domainId];
      const community = selectCommunitiesMap(state)[domain?.community?.id];
      const businessUnit = selectBusinessUnitsMap(state)[component?.businessUnitInCharge];
      return ({
        component,
        submitFailed: hasSubmitFailed(props.formName)(state),
        isLoading: selectIsCreateProductLoading(state, props.formName)
          || selectIsEditProductLoading(state, props.formName),
        // Deep equal to check if there are unsaved changes.
        isDraftFullySaved: isEqual(
          fromFormToStore(getFormValues(props.formName)(state)),
          fromFormToStore(getFormInitialValues(props.formName)(state)),
        ),
        isPublishable: (props.formMode === FORM_MODES.CREATE)
          || getFormValues(props.formName)(state).isDraft,
        hasErrors: !isEmpty(getFormAsyncErrors(props.formName)(state))
          || !isEmpty(getFormSyncErrors(props.formName)(state)),
        listErrors: getFormSyncErrors(props.formName)(state),
        domain,
        community,
        businessUnit,
      });
    },
  ),

  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.SUBSETS),
    onClickLeaveButton: ({ history, isDraftFullySaved, onOpenLeaveDialog }) => () => {
      if (isDraftFullySaved) {
        history.push(ROUTES.HOME);
      } else {
        onOpenLeaveDialog();
      }
    },
    handleSubmitLeave: ({ history }) => () => {
      history.push(ROUTES.HOME);
    },
    handleResendValidation: ({ history }) => () => {
      history.push(ROUTES.HOME);
    },
  }),

  withProductFromFormValues,

  withCurrentUser,

  memo,
);

const submitButtonTexts = {
  [validationStatuses.PP]: messages.PUBLISH,
  [validationStatuses.APPROVED]: messages.PUBLISH,
  [validationStatuses.RBDL]: messages.SUBMIT_FOR_DL_VALIDATION,
  [validationStatuses.RBCA]: messages.SUBMIT_FOR_CA_VALIDATION,
  [validationStatuses.RCA]: messages.SUBMIT_FOR_CA_VALIDATION,
  [validationStatuses.PDL]: messages.VALIDATE,
  [validationStatuses.PCA]: messages.VALIDATE,
  [validationStatuses.PBUA]: messages.VALIDATE,
  [validationStatuses.RDLV]: messages.SUBMIT_FOR_DL_VALIDATION,
  [validationStatuses.RBUA]: messages.SUBMIT_FOR_BUA_VALIDATION,
  [validationStatuses.RBBUA]: messages.SUBMIT_FOR_BUA_VALIDATION,
  REJECT: messages.REJECT,
};

const hintHeaderMessages = {
  [validationStatuses.PDL]: messages.PDL_HEADER_HINT,
  [validationStatuses.PCA]: messages.PCA_HEADER_HINT,
  [validationStatuses.PBUA]: messages.PBUA_HEADER_HINT,
  [validationStatuses.RBDL]: messages.RBDL_HEADER_HINT,
  [validationStatuses.RBCA]: messages.RBCA_HEADER_HINT,
  [validationStatuses.RBBUA]: messages.RBBUA_HEADER_HINT,
  [validationStatuses.RDLV]: messages.RDLV_HEADER_HINT,
  [validationStatuses.RCA]: messages.RCA_HEADER_HINT,
  [validationStatuses.RBUA]: messages.RBUA_HEADER_HINT,
  [validationStatuses.PBUA]: messages.PBUA_HEADER_HINT,
  [validationStatuses.RBBUA]: messages.RBBUA_HEADER_HINT,
};

const hintMainMessages = {
  [validationStatuses.PDL]: messages.PDL_MAIN_HINT,
  [validationStatuses.PCA]: messages.PCA_MAIN_HINT,
  [validationStatuses.RBDL]: messages.RBDL_MAIN_HINT,
  [validationStatuses.RBCA]: messages.RBCA_MAIN_HINT,
  [validationStatuses.RDLV]: messages.RDLV_MAIN_HINT,
  [validationStatuses.RBUA]: messages.RBUA_MAIN_HINT,
  [validationStatuses.RCA]: messages.RCA_MAIN_HINT,
  [validationStatuses.PBUA]: messages.PBUA_MAIN_HINT,
  [validationStatuses.RBBUA]: messages.RBBUA_MAIN_HINT,

};

const hintHeaderMessage = (component) => {
  if (component.validation?.status === validationStatuses.PP) {
    return <FormattedMessage {...messages.LAST_STEP_CREATE} />;
  }

  return (
    <div>
      <FormattedMessage {...hintHeaderMessages[component.validation?.status]} />
    </div>
  );
};

const hintMainMessage
  = (user, component, digitalDomainLeader, communityArchitect, businessUnitArchitect) => {
    if (component.validation?.status === validationStatuses.PP) {
      return (
        <>
          <p><FormattedMessage {...messages.PUBLISH_HINT} /></p>
          <p><FormattedMessage {...messages.CREATE_PUBLISH_NOTICE} /></p>

        </>
      );
    }
    if (component.validation?.status === validationStatuses.PDL
    && !userIsProductDomainLeader(user, component)) {
      return (
        <div>
          <ValidatorPerson
            teamMembers={[{
              externalId: digitalDomainLeader,
            }]}
          />
          <FormattedMessage {...hintMainMessages[component.validation?.status]} />
        </div>
      );
    }
    if (component.validation?.status === validationStatuses.PCA
    && !userIsProductCommunityArchitect(user, component)) {
      return (
        <div>
          <ValidatorPerson
            teamMembers={[{
              externalId: communityArchitect,
            }]}
          />
          <FormattedMessage {...hintMainMessages[component.validation?.status]} />
        </div>
      );
    }

    if (component.validation?.status === validationStatuses.PBUA
    && !userIsBusinessUnitArchitect(user, component)) {
      return (
        <div>
          <ValidatorPerson
            teamMembers={[{
              externalId: businessUnitArchitect,
            }]}
          />
          <FormattedMessage {...hintMainMessages[component.validation?.status]} />
        </div>
      );
    }

    return (
      <div>
        <FormattedMessage {...hintMainMessages[component.validation?.status]} />
      </div>
    );
  };

const {
  fields: {
    rejectedReason: { component: RejectedReasonField },
    rejectedReasonDescription: { component: RejectedReasonDescriptionField },
  },
} = manifest;

const displayLeadersActions = (user, component, defaultDisplay) => {
  if (!component.validation) return defaultDisplay;

  if (component.validation.status === validationStatuses.PDL) {
    return userIsProductDomainLeader(user, component);
  }

  if (component.validation.status === validationStatuses.PCA) {
    return userIsProductCommunityArchitect(user, component);
  }

  if (component.validation.status === validationStatuses.PBUA) {
    return userIsBusinessUnitArchitect(user, component);
  }

  return defaultDisplay;
};

const hourInMs = 3600000;
const dayInMs = 24 * hourInMs;

const ComponentFormValidation = ({
  submitFailed,
  isLoading,
  isPublishable,
  onClickPreviousStep,
  onClickLeaveButton,
  onOpenPreview,
  isDraftFullySaved,
  hasErrors,
  isLeaveDialogOpen,
  onCloseLeaveDialog,
  handleSubmitLeave,
  handleResendValidation,
  component: componentFromForm,
  user: currentUser,
  domain,
  community,
  businessUnit,
}) => {
  const buttonPublish = componentFromForm.validation && (
    <Button
      disabled={hasErrors || isLoading}
      type="submit"
      className={classNames.button}
    >
      {
        componentFromForm.validation?.rejectedReason
        && componentFromForm.validation?.rejectedReason !== 'N/A'
        && (
          componentFromForm.validation?.status === validationStatuses.PDL
          || componentFromForm.validation?.status === validationStatuses.PCA
          || componentFromForm.validation?.status === validationStatuses.PBUA
        ) ?
          <FormattedMessage {...submitButtonTexts.REJECT} />
          : <FormattedMessage {...submitButtonTexts[componentFromForm.validation?.status]} />
      }
      <Asset className={classNames.buttonIcon} name={ICON_MAP.send} />
    </Button>
  );

  const buttonSave = (
    <Button
      disabled={hasErrors || isLoading}
      type="submit"
      className={classNames.button}
    >
      <FormattedMessage {...globalMessages.SAVE} />
      <Asset className={classNames.buttonIcon} name={ICON_MAP.send} />
    </Button>
  );

  const lastValidationTimestamp = componentFromForm?.validation?.lastValidationTimestamp ?
    new Date(componentFromForm?.validation?.lastValidationTimestamp) : undefined;

  const canResendValidation = !lastValidationTimestamp
    || (lastValidationTimestamp
      && (Date.now() - lastValidationTimestamp?.getTime() > dayInMs)
    );

  const hoursLeftUntilResend
    = Math.abs(Math.round((Date.now() - (lastValidationTimestamp?.getTime() + dayInMs)) / 3600000));

  const resendButton = (
    componentFromForm.validation?.status === validationStatuses.PDL
    || componentFromForm.validation?.status === validationStatuses.PCA
    || componentFromForm.validation?.status === validationStatuses.PBUA) ? (
      <Tooltip title={canResendValidation ? null : (
        <FormattedMessage
          {...messages.VALIDATION_REQUEST_TIMEOUT}
          values={{ hoursLeftUntilResend }}
        />
      )}
      >
        <Button
          outline
          overrideClassName={classNames.resendButton}
          overrideClassNames={classNames}
          onClick={async () => {
            await editProduct(componentFromForm.id, componentFromForm, true, true);
            handleResendValidation();
          }}
          disabled={!canResendValidation}
        ><FormattedMessage {...messages.RESEND_VALIDATION_REQUEST} />
        </Button>
      </Tooltip>

    ) : null;

  const shouldDisplayLeadersActions = displayLeadersActions(currentUser, componentFromForm, true);

  return (
    <ComponentFormStepLayout
      stepKey={COMPONENT_FORM_STEPS.VALIDATION}
      onClickPreviousStep={onClickPreviousStep}
      onOpenPreview={onOpenPreview}
      form={(
        <div className={classNames.container}>
          <Asset
            className={classNames.bigIcon}
            aria-hidden
            name={ICON_MAP.send}
          />

          {componentFromForm.validation ? (
            <>
              <h2>
                { isPublishable ?
                  hintHeaderMessage(componentFromForm)
                  : <FormattedMessage {...messages.LAST_STEP_EDIT} /> }
              </h2>

              <div className={classNames.notice}>
                { isPublishable && domain && community && businessUnit && (
                  hintMainMessage(
                    currentUser,
                    componentFromForm,
                    domain.digitalDomainLeader,
                    community.communityArchitect,
                    businessUnit.businessUnitArchitect,
                  )
                )
                }
              </div>
            </>
          )
            : <h2><FormattedMessage {...messages.INCOMPLETE_COMPONENT} /></h2>
          }

          {
            componentFromForm.isDraft
            && displayLeadersActions(currentUser, componentFromForm, false)
            && (
              <div style={{ width: '100%', marginTop: '3rem' }}>
                <RejectedReasonField
                  isCommunityLeader={
                    userIsProductCommunityArchitect(currentUser, componentFromForm)
                  }
                />
                <RejectedReasonDescriptionField disabled={
                  !componentFromForm.validation?.rejectedReason || componentFromForm.validation?.rejectedReason === 'N/A'
                }
                />
              </div>
            )
          }

          {componentFromForm.validation && (
          <div className={classNames.buttonsContainer}>
            { !shouldDisplayLeadersActions && resendButton }
            { isPublishable ? (
              <>
                {
                  shouldDisplayLeadersActions && (
                  <div className={classNames.buttonContainer}>
                    { (hasErrors || submitFailed) ? (
                      <Tooltip title={<FormattedMessage {...messages.SUBMIT_FAILED} />}>
                        { buttonPublish }
                      </Tooltip>
                    ) : (
                      buttonPublish
                    ) }
                  </div>
                  )
                }

                <Tooltip
                  title={(
                    (!isLoading && !isDraftFullySaved)
                    && <FormattedMessage {...messages.DRAFT_FAILED_HINT} />
                  )}
                >
                  <div className={classNames.buttonContainer}>
                    <Button
                      className={classNames.button}
                      onClick={onClickLeaveButton}
                    >
                      <FormattedMessage {...messages.LEAVE} />
                      <Asset className={classNames.buttonIcon} name={ICON_MAP.leave} />
                    </Button>
                  </div>
                </Tooltip>
              </>
            ) : (
              <div className={classNames.buttonContainer}>
                { (hasErrors || submitFailed) ? (
                  <Tooltip title={<FormattedMessage {...messages.SUBMIT_FAILED} />}>
                    { buttonSave }
                  </Tooltip>
                ) : (
                  buttonSave
                ) }
              </div>
            ) }
          </div>
          ) }
          <DialogLeave
            isModalOpen={isLeaveDialogOpen}
            onCloseModal={onCloseLeaveDialog}
            onRequest={handleSubmitLeave}
          />
        </div>
      )}
    />
  );
};

ComponentFormValidation.displayName = 'ComponentFormValidation';

ComponentFormValidation.propTypes = {
  submitFailed: PropTypes.bool,
  isPublishable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDraftFullySaved: PropTypes.bool.isRequired,
  onClickPreviousStep: PropTypes.func.isRequired,
  onClickLeaveButton: PropTypes.func,
  onOpenPreview: PropTypes.func,
  hasErrors: PropTypes.bool,
  isLeaveDialogOpen: PropTypes.bool,
  onCloseLeaveDialog: PropTypes.func,
  handleSubmitLeave: PropTypes.func,
  handleResendValidation: PropTypes.func,
  component: productShape.isRequired,
  user: currentUserShape.isRequired,
  domain: domainShape,
  community: communityShape,
  businessUnit: businessUnitShape,
};

ComponentFormValidation.defaultProps = {
  submitFailed: false,
  onOpenPreview: noop,
  onClickLeaveButton: noop,
  hasErrors: true,
  isLeaveDialogOpen: false,
  onCloseLeaveDialog: noop,
  handleSubmitLeave: noop,
  handleResendValidation: noop,
  domain: null,
  community: null,
  businessUnit: null,
};

export default enhancer(ComponentFormValidation);
