import { defineMessages } from 'react-intl';

export default defineMessages({
  HOURS: { id: 'product.fields.hours', defaultMessage: '{hoursNo} {hoursNo, plural, =0 {Hours} one {Hour} other {Hours}}' },
  MINUTES: { id: 'product.fields.minutes', defaultMessage: '{minutesNo} {minutesNo, plural, =0 {Minutes} one {Minute} other {Minutes}}' },

  PRODUCT_ADDITIONAL_INFO_FORM_DIALOG_TITLE: { id: 'product.info.form.title', defaultMessage: 'Other product information' },
  PRODUCT_ADDITIONAL_INFO_FORM_SUCCESS_TITLE: { id: 'product.info.success.title', defaultMessage: 'Thank you' },
  PRODUCT_ADDITIONAL_INFO_FORM_ORGANIZATION: { id: 'product.info.organization', defaultMessage: 'Organization' },
  PRODUCT_ADDITIONAL_INFO_FORM_BUSINESS_IMPACT_ANALYSIS: { id: 'product.info.businessImpactAnalysis', defaultMessage: 'Business Impact Analysis' },
  PRODUCT_ADDITIONAL_INFO_FORM_SUCCESS_MESSAGE: { id: 'product.info.success.message', defaultMessage: 'Your product information was updated successfully.' },
  PRODUCT_ADDITIONAL_INFO_FORM_FAILURE_TITLE: { id: 'product.info.failure.title', defaultMessage: 'An error occurred' },
  PRODUCT_ADDITIONAL_INFO_FORM_FAILURE_MESSAGE: { id: 'product.info.failure.message', defaultMessage: 'Your product information could not be updated.' },
  // Field Software publisher useless at this moment, keep it here if they need to re-use it later
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_SOFTWARE_PUBLISHER: { id: 'product.info.form.fields.softwarePublisher', defaultMessage: 'Software publisher' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAC: { id: 'product.info.form.fields.ciac', defaultMessage: 'C (Confidentiality)' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAI: { id: 'product.info.form.fields.ciai', defaultMessage: 'I (Integrity)' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAA: { id: 'product.info.form.fields.ciaa', defaultMessage: 'A (Availability)' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_SLA: { id: 'product.info.form.fields.sla', defaultMessage: 'SLA' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_BIA: { id: 'product.info.form.fields.bia', defaultMessage: 'BIA' },
  // Field useless users number at this moment, keep it here if they need to re-use it later
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_USERS_NUMBER: { id: 'product.info.form.fields.numberOfUsers', defaultMessage: 'Number of users' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA: { id: 'product.info.form.fields.personalData', defaultMessage: 'Personal Data' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA_TOOLTIP: {
    id: 'product.info.form.fields.personalDataTooltip',
    defaultMessage: 'Does the digital product involve the processing of {link}?',
  },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA_TOOLTIP_LINK: { id: 'product.info.form.fields.personalDataTooltip.link', defaultMessage: 'personal data' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PSI: { id: 'product.info.form.fields.drp', defaultMessage: 'DRP Eligibility (Disaster Recovery Plan)' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE: { id: 'product.info.form.fields.last_bia_date', defaultMessage: 'Last BIA Date' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE_TOOLTIP: {
    id: 'product.info.form.fields.lastBiaDAteTooltip',
    defaultMessage: 'Last date of business impact analysis. More informations {link} !',
  },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE_TOOLTIP_LINK: { id: 'product.info.form.fields.lastBiaDAteTooltip.link', defaultMessage: 'here' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_ALIASES: { id: 'product.info.form.fields.aliases', defaultMessage: 'Aliases' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_INVOICING_MODEL: { id: 'product.info.form.fields.invoicingModel', defaultMessage: 'Invoicing model' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_PREFIX: { id: 'product.info.form.fields.githubPrefix', defaultMessage: 'Github repository prefix' },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_TOOLTIP: {
    id: 'product.info.form.fields.githubPrefixTooltip',
    defaultMessage: 'The prefix of your github repositories (Please see {link} about Adeo naming conventions)',
  },
  PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_TOOLTIP_LINK: { id: 'product.info.form.fields.githubPrefixTooltip.link', defaultMessage: 'this page' },
  PRODUCT_ADDITIONAL_INFO_INVOICING_MODEL_PPU: { id: 'product.info.form.fields.invoicingModel.payPerUse', defaultMessage: 'Pay-per-use' },
  PRODUCT_ADDITIONAL_INFO_INVOICING_MODEL_OTH: { id: 'product.info.form.fields.invoicingModel.other', defaultMessage: 'Other' },

  PRODUCT_ADDITIONAL_INFO_HOURS: { id: 'product.info.form.fields.hours', defaultMessage: 'This represents the number of Hours' },
  PRODUCT_ADDITIONAL_INFO_MINUTES: { id: 'product.info.form.fields.minutes', defaultMessage: 'This represents the number of Minutes' },

  PRODUCT_RECOVERY_POINT_OBJECTIVE_RECOMMENDED: { id: 'product.recovery.point.objective.recommended', defaultMessage: 'RPO (Recovery Point Objective  recommended)' },
  PRODUCT_RECOVERY_TIME_OBJECTIVE_RECOMMENDED: { id: 'recovery.time.objective.recommended', defaultMessage: 'RTO (Recovery Time Objective recommended)' },
  PRODUCT_OPERATIONS: { id: 'product.operations', defaultMessage: 'Operations' },
  PRODUCT_SUPPORT_HOURS_RANGE: { id: 'product.support.hours.range', defaultMessage: 'Support hours range' },
  PRODUCT_SUPPORT_DAYS_AVAILABLE: { id: 'product.support.days.available', defaultMessage: 'Support days available' },
  PRODUCT_RECOVERY_POINT_OBJECTIVE: { id: 'product.recovery.point.objective', defaultMessage: 'RPO (Recovery Point Objective defined)' },
  PRODUCT_RECOVERY_TIME_OBJECTIVE: { id: 'product.recovery.time.objective', defaultMessage: 'RTO (Recovery Time Objective defined)' },
  PRODUCT_SCHEDULED_MAINTENANCE: { id: 'product.scheduled.maintenance', defaultMessage: 'Scheduled maintenance' },
  PRODUCT_INCIDENT_MANAGEMENT: { id: 'product.incident.management', defaultMessage: 'Incident management' },
  PRODUCT_INCIDENT_TAKING_INTO_ACCOUNT: { id: 'product.incident.taking.into.account', defaultMessage: 'Incident taking into account' },
  PRODUCT_URGENCE_1_BLOCKING: { id: 'product.urgence.1.blocking', defaultMessage: 'Urgence 1 (Blocking)' },
  PRODUCT_URGENCE_2_DEGRADED_SITUATION: { id: 'product.urgence.2.degraded.situation', defaultMessage: 'Urgence 2 (Degraded situation)' },
  PRODUCT_URGENCE_3_NON_BLOCKING: { id: 'product.urgence.3.non.blocking', defaultMessage: 'Urgence 3 (Non blocking)' },
  PRODUCT_REQUESTS_MANAGEMENT: { id: 'product.requests.management', defaultMessage: 'Requests management' },
  PRODUCT_TAKING_INTO_ACCOUNT_REQUESTS: { id: 'product.taking.into.account.requests', defaultMessage: 'Taking into account requests' },
  PRODUCT_REQUESTED_SERVICES: { id: 'product.requested.services', defaultMessage: 'Requested services' },
  PRODUCT_SERVICE_CATALOG: { id: 'product.service.catalog', defaultMessage: 'Service catalog' },
  PRODUCT_SERVICE_LEVEL_INDICATORS: { id: 'product.service.level.indicators', defaultMessage: 'Service level indicators' },
  PRODUCT_AVAILABILITY: { id: 'product.availability', defaultMessage: 'Availability' },
  PRODUCT_AVAILABILITY_INFO: { id: 'product.availability.info', defaultMessage: 'Availability info' },
  PRODUCT_AVAILABILITY_INFO_LINK: { id: 'product.availability.info.link', defaultMessage: 'Availability info link' },

  PRODUCT_LATENCY_INFO: { id: 'product.latency.info', defaultMessage: 'Latency info' },
  PRODUCT_LATENCY_INFO_LINK: { id: 'product.latency.info.link', defaultMessage: 'Latency info link' },

  PRODUCT_UPTIME_INFO: { id: 'product.uptime.info', defaultMessage: 'Uptime Info' },
  PRODUCT_UPTIME_INFO_LINK: { id: 'product.uptime.info.link', defaultMessage: 'Uptime Info link' },
});
