import React from 'react';
import { FormattedMessage } from 'react-intl';

import { productShape } from 'shapes/product';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import BusinessActivitiesTree from 'react/business/businessActivities/tree/BusinessActivitiesTree';
import connectBusinessActivitiesTree from 'react/business/businessActivities/tree/connect/business-activities-tree.connect';

import messages from 'config/business.messages';
import classNames from './product-business-activities.module.scss';

const ConnectedBusinessActivitiesTree = connectBusinessActivitiesTree(BusinessActivitiesTree);

const ProductBusinessActivities = ({ product }) => (
  <Section theme="dark">
    <SectionTitle centered>
      <FormattedMessage {...messages.BUSINESS_ACTIVITIES} />
    </SectionTitle>

    <div className={classNames.treeContainer}>
      <ConnectedBusinessActivitiesTree
        product={product}
      />
    </div>
  </Section>
);

ProductBusinessActivities.displayName = 'ProductBusinessActivities';

ProductBusinessActivities.propTypes = {
  product: productShape,
};

ProductBusinessActivities.defaultProps = {
  product: {},
};

export default ProductBusinessActivities;
