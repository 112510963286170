import { defineMessages } from 'react-intl';

export default defineMessages({
  BUSINESS_UNIT_HINT: {
    id: 'pages.component.form.step.3.buInChargeOf.hint',
    defaultMessage: 'Select the Business Unit which is in charge of this digital component, if applicable.',
  },

  TEAM_HINT: {
    id: 'pages.component.form.step.3.team',
    defaultMessage: 'Here, the goal is not necessarily to present the entire team but to identify the key component players who will be able to answer to any requests. At least the Digital Component Leader and the Business Component Leader.',
  },

  HEADER_TITLE_BU_IN_CHARGE: {
    id: 'pages.component.form.step.3.bu.header.title',
    defaultMessage: 'Business unit in charge',
  },

  HEADER_TITLE_TEAM: {
    id: 'pages.component.form.step.3.team.header.title',
    defaultMessage: 'Project team members',
  },

  NOTIFICATION_PANEL_TITLE_MCC: {
    id: 'pages.component.form.step.3.notification.panel.title.mcc',
    defaultMessage: 'TEAM MANAGEMENT',
  },

  NOTIFICATION_PANEL_MESSAGE_MCC: {
    id: 'pages.component.form.step.3.notification.panel.message.mcc',
    defaultMessage: 'Please be aware that changing a business role may impact the member\'s permissions.',
  },
});
