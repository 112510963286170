import { compose } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { withComponentFormName } from 'react/business/components/form/Context';
import { selectProductFormValuesAsStoreProduct } from 'redux/products/selectors';

export default compose(
  withComponentFormName(),

  connect(
    (state, props) => ({
      component: selectProductFormValuesAsStoreProduct(state, props.formName),
    }),
  ),
);
