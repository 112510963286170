import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatRoute } from 'react-router-named-routes';

import { PRODUCT_VIEW } from 'config/constants';
import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';
import ROUTES from 'react/routes';

import Link from 'react/generic/link/Link';
import productMessages from 'react/business/products/product.messages';

import { checkIfProductTitleExists } from 'services/searches';
import { getUsersByExternalIds } from 'services/users';
import messages from './form-validation.messages';

/**
 * List fields that are supposed to trigger an async validation.
 *
 * @see https://redux-form.com/8.1.0/examples/asyncchangevalidation/
 * @type {string[]}
 */
export const asyncChangeFields = ['name'];

/**
 * Provides an async validation for the fields.
 *
 * @async
 * @param {object} values - Form values.
 * @param {Function} dispatch - Dispatch function.
 * @param {object} props - Props values.
 */
export const asyncValidate = async (values, dispatch, props) => {
  const result = await checkIfProductTitleExists(values.name, values.id, true);

  const nameExists = !!result.length;

  if (nameExists) {
    const component = result[0];
    const currentUserId = props?.currentUser.externalId;

    if (component.isDraft) {
      if (component.author === currentUserId) {
        // eslint-disable-next-line no-throw-literal
        throw {
          name: (
            <>
              <FormattedMessage {...messages.NAME_TAKEN_DRAFT_OWN} />
              {' '}
              <Link
                to={formatRoute(ROUTES.COMPONENT.EDIT_FORM, {
                  componentId: component.id,
                  step: COMPONENT_FORM_STEPS.PRESENTATION,
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.GO_TO_IT} />
              </Link>
            </>
          ),
        };
      }
      const [author] = await getUsersByExternalIds([component.author]);

      // eslint-disable-next-line no-throw-literal
      throw {
        name: (
          <>
            <FormattedMessage {...messages.NAME_TAKEN_DRAFT} />
            {' '}
            <FormattedMessage {...productMessages.AUTHOR} />
            {
              (author) ? `: ${author?.firstName} ${author?.lastName} (${component.author})`
                : `: ${component.author}`
            }
          </>
        ),
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      name: (
        <>
          <FormattedMessage {...messages.NAME_TAKEN} />
          {' '}
          <Link
            to={formatRoute(ROUTES.COMPONENT.PRESENTATION, {
              componentId: component.id,
              view: PRODUCT_VIEW.OVERVIEW,
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage {...messages.GO_TO_IT} />
          </Link>
        </>
      ),
    };
  }
};
