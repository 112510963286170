import connect from 'react/hoc/connectProxy';
import { compose, lifecycle } from 'recompose';

import {
  selectMacroActivitiesMap,
  selectActivitiesMap,
  selectAtomicActivitiesMap,
  selectIsLoadingAny,
} from 'redux/businessActivities/selectors';
import { getAtomicActivities, getActivities, getMacroActivities } from 'redux/businessActivities/actions';

export const withBusinessActivitiesMaps = connect(
  (state, props) => ({
    value: props.product.atomicActivities,
    macroActivitiesMap: selectMacroActivitiesMap(state),
    activitiesMap: selectActivitiesMap(state),
    atomicActivitiesMap: selectAtomicActivitiesMap(state),

    isLoading: selectIsLoadingAny(state),
  }), {
    getMacroActivities: () => getMacroActivities(),
    getActivities: filters => getActivities(filters),
    getAtomicActivities: filters => getAtomicActivities(filters),
  },
);

export default compose(
  withBusinessActivitiesMaps,

  lifecycle({
    /**
     * Load business activities.
     *
     * @async
     */
    async componentDidMount() {
      const {
        product: {
          activities,
          atomicActivities,
        },
      } = this.props;

      if (atomicActivities.length > 0) {
        await Promise.all([
          this.props.getMacroActivities(),
          this.props.getActivities({ activityIds: activities }),
          this.props.getAtomicActivities({ atomicActivityIds: atomicActivities }),
        ]);
      }
    },
  }),
);
