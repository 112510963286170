import { defineMessages } from 'react-intl';

export default defineMessages({
  SUBSET_TITLE: {
    id: 'pages.component.form.step.8.subset.title',
    defaultMessage: 'Subsets related to the component',
  },
  SUBSET_HINT: {
    id: 'pages.component.form.step.8.subset.hint',
    defaultMessage: 'Which subsets are related to your component?',
  },
});
