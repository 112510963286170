import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';

import { formShape } from 'rc-form';
import createProductAdditionalInfoForm from './createProductAdditionalInfoForm.container';

export default (WrappedComponent) => {
  const enhancer = compose(
    createProductAdditionalInfoForm,
  );

  const createProductAdditionalInfoFormChildrenContainer = ({
    onSubmit,
    children,
    ...props
  }) => (
    <form onSubmit={onSubmit}>
      { children({
        form: (
          <WrappedComponent {...props} />
        ),
        rcForm: props.form,
      }) }
    </form>
  );

  createProductAdditionalInfoFormChildrenContainer.displayName = 'createProductAdditionalInfoFormChildrenContainer';

  createProductAdditionalInfoFormChildrenContainer.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.func,
    form: formShape,
  };

  createProductAdditionalInfoFormChildrenContainer.defaultProps = {
    onSubmit: noop,
    children: noop,
    form: null,
  };

  return enhancer(createProductAdditionalInfoFormChildrenContainer);
};
