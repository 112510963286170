import agent from 'services/http';

const e = encodeURIComponent;

/**
 * GET /api/security/:productId.
 * Get the security data of an entity.
 *
 * @async
 * @param {string} entityId - Entity id.
 * @returns {{object}} Results.
 */
export async function getSecurity(entityId) {
  const { body } = await agent
    .get(`/api/security/${e(entityId)}`);
  return (body || {});
}

/**
 * PATCH /api/security/:entityId.
 *
 * @async
 * @param {string} entityId - Entity concerned by this data.
 * @param {object} security - Security data to update.
 * @returns {object} Updated security ata.
 */
export async function patchSecurity(entityId, security) {
  const body = await agent
    .patch(`/api/security/${e(entityId)}`)
    .send(security);

  return body;
}
