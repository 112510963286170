import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import globalMessages from 'config/global.messages';
import { productShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import Button from 'react/generic/button/Button';
import { securityShape } from 'shapes/security';
import withSubmitForm from '../steps/withSubmitForm';
import createProductAdditionalInfoFormChildrenContainer from '../createProductAdditionalInfoForm.children.container';
import CreateProductAdditionalInfoForm from '../createProductAdditionalInfoForm.presentation';

import classNames from './productAdditionalInfoFormStandalone.module.scss';

const CreateProductAdditionalInfoFormChildren = compose(
  createProductAdditionalInfoFormChildrenContainer,
)(CreateProductAdditionalInfoForm);

const enhancer = compose(
  withSubmitForm,

  memo,
);

const ProductAdditionalInfoFormStandalone = ({
  product, isLoading, onSubmit, onCancelEdit, security,
}) => (
  <CreateProductAdditionalInfoFormChildren
    product={product}
    security={security}
    onSubmit={onSubmit}
  >
    {({ form, rcForm }) => {
      const isNotValid = () => Object.values(rcForm.getFieldsError()).some(item => !!item);
      return (
        <>
          {form}
          <div className={classNames.button}>
            <Button disabled={isLoading || isNotValid()} type="submit">
              <FormattedMessage {...globalMessages.SAVE} />
            </Button>
            <Button onClick={onCancelEdit} disabled={isLoading}>
              <FormattedMessage {...globalMessages.CANCEL} />
            </Button>
          </div>
        </>
      );
    }}
  </CreateProductAdditionalInfoFormChildren>
);

ProductAdditionalInfoFormStandalone.displayName = 'ProductAdditionalInfoFormStandalone';

ProductAdditionalInfoFormStandalone.propTypes = {
  product: productShape,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onCancelEdit: PropTypes.func,
  security: securityShape,
};

ProductAdditionalInfoFormStandalone.defaultProps = {
  product: {},
  isLoading: false,
  onCancelEdit: noop,
  security: null,
};

export default enhancer(ProductAdditionalInfoFormStandalone);
