import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { activityShape, atomicActivityShape, macroActivityShape } from 'shapes/businessActivities';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import classNames from '../business-activities-tree.module.scss';

const BusinessActivitiesTreeElement = ({
  element,
  children,
  className,
}) => (
  <li className={cn(className)}>
    <span className={classNames.titleContainer}>
      {element.name}
      {element.description && (
        <Tooltip title={element.description}>
          <Asset className={classNames.infoIcon} name={ICON_MAP.info} />
        </Tooltip>
      )}
    </span>
    <ul>
      {children}
    </ul>
  </li>
);

BusinessActivitiesTreeElement.propTypes = {
  element: PropTypes.oneOfType([
    macroActivityShape,
    activityShape,
    atomicActivityShape,
  ]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

BusinessActivitiesTreeElement.defaultProps = {
  children: null,
  className: '',
};

export default memo(BusinessActivitiesTreeElement);
