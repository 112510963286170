import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { PulseSpinner } from 'react-spinners-kit';
import { compose } from 'recompose';

import {
  activityShape, atomicActivityShape, macroActivityShape, treeElementShape,
} from 'shapes/businessActivities';
import theme from 'style/theme.variables.scss';

import classNames from './business-activities-tree.module.scss';
import BusinessActivitiesTreeElement from './treeElement/BusinessActivitiesTreeElement';
import withTreeFromAtomicActivities from './withTreeFromAtomicActivities';

const enhancer = compose(
  // Compute the tree.
  withTreeFromAtomicActivities,
);

class BusinessActivitiesTree extends React.PureComponent {
  static propTypes = {
    macroActivitiesMap: PropTypes.objectOf(macroActivityShape),
    activitiesMap: PropTypes.objectOf(activityShape),
    atomicActivitiesMap: PropTypes.objectOf(atomicActivityShape),

    // Coming from the HOC withTreeFromAtomicActivities.
    tree: PropTypes.arrayOf(treeElementShape).isRequired,

    isLoading: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    macroActivitiesMap: {},
    activitiesMap: {},
    atomicActivitiesMap: {},
    isLoading: false,
    className: '',
  };

  /**
   * Render component.
   *
   * @returns {object} - JSX.
   */
  render() {
    const {
      macroActivitiesMap,
      activitiesMap,
      atomicActivitiesMap,
      tree,
      isLoading,

      className,
    } = this.props;

    return (
      <div className={cn(classNames.container, className)}>
        {isLoading ? (
          <div className={classNames.spinnerContainer}>
            <PulseSpinner color={theme.primary} />
          </div>
        ) : (
          <ul>
            {tree.map(macroActivity => (
              <BusinessActivitiesTreeElement
                element={macroActivitiesMap[macroActivity.id]}
                key={macroActivity.id}
              >
                {macroActivity.children.map(activity => (
                  <BusinessActivitiesTreeElement
                    element={activitiesMap[activity.id]}
                    key={activity.id}
                  >
                    {activity.children.map(atomicActivity => (
                      <BusinessActivitiesTreeElement
                        element={atomicActivitiesMap[atomicActivity.id]}
                        key={atomicActivity.id}
                      />
                    ))}
                  </BusinessActivitiesTreeElement>
                ))}
              </BusinessActivitiesTreeElement>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default enhancer(BusinessActivitiesTree);
