import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import manifest from './business-activities-form.manifest';

import messages from './business-activities-form.messages';

const {
  fields: {
    atomicActivities: { component: AtomicActivitiesField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const BusinessActivitiesForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE} />}
        message={<FormattedMessage {...messages.BUSINESS_ACTIVITIES_HINT} />}
      />

      <AtomicActivitiesField />
    </FieldsGroup>
  </>
);

export default enhancer(BusinessActivitiesForm);
