import { noop } from 'lodash';
import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { productShape, reviewShape } from 'shapes/product';
import { filtersShape } from 'react/business/review/reviews.shapes';

import Evaluation from 'react/generic/evaluation/Evaluation';
import withDialogState from 'react/generic/dialog/withDialogState';
import ReviewCardWithRepliesCards from 'react/business/review/withReplies/ReviewCardWithRepliesCards';
import CreateReviewFormDialog from 'react/business/review/form/dialog/create/CreateReviewFormDialog';
import ReviewFilters from 'react/business/review/filters/ReviewFilters';
import ProductSubHeader from 'react/business/products/details/subHeader/ProductSubHeader';
import ContributeButton from 'react/business/products/editButton/ContributeButton';
import LoadMore from 'react/business/review/pagination/loadMore/LoadMore.presentation';
import withPaginatedReviewsContainer from 'react/business/review/pagination/PaginatedReviews.container';

import NumberOfItemsOnPageFilter from 'react/generic/filter/NumberOfItemsOnPage';
import withNumberOfItemsOnPage from 'react/generic/filter/withNumberOfItemsOnPage';
import { REVIEWS_PAGINATION_ITEM_NUMBER_OPTIONS } from 'config/constants';
import { preferencesShape } from 'shapes/preferences';
import { withRouter } from 'react-router';
import ROUTES from 'react/routes';
import { matchShape } from 'shapes/router';

import { withComponentPageContext } from '../../ComponentPage.context';

import messages from './feedback-page.messages';
import classNames from './feedback-page.module.scss';

const enhancer = compose(
  withRouter,

  withDialogState('CreateReview'),

  withComponentPageContext(({ component }) => ({ component })),

  withPaginatedReviewsContainer,

  withNumberOfItemsOnPage,

  memo,
);

const FeedbackPage = ({
  component,
  reviews,
  onClickReply,
  filters,
  onSetFilter,
  isCreateReviewDialogOpen,
  onOpenCreateReviewDialog,
  onCloseCreateReviewDialog,
  onSubmitReviewSuccess,
  currentCount,
  totalCount,
  onLoadPage,
  onSetNumberOfReviewsOnPage,
  userPreferences,
  match,
}) => {
  const isSingle = () => match.path === ROUTES.PRODUCT.PRESENTATION_SINGLE
    && match.params.messageId;

  const proxyOnLoadPage = () => onLoadPage(component.id, { preserve: true });

  return (
    <>
      <ProductSubHeader>
        <div className={classNames.buttonsContainer}>
          <div>
            <ContributeButton
              onClickEdit={onOpenCreateReviewDialog}
              message={<FormattedMessage {...messages.GIVE_YOURS} />}
            />
          </div>
          { !isSingle() && (
            <div>
              <NumberOfItemsOnPageFilter
                onSetNumberOfItems={onSetNumberOfReviewsOnPage}
                currentItemsNoValue={userPreferences.reviewsOnPage}
                options={REVIEWS_PAGINATION_ITEM_NUMBER_OPTIONS}
                messageProps={
                  {
                    message: messages.REVIEWS_NO,
                    valuesPlaceholder: 'reviewsNo',
                  }
                }
              />
            </div>
          ) }
        </div>
        { !isSingle() && (
          <div className={classNames.filtersContainer}>
            <ReviewFilters
              filters={filters}
              onSetFilter={onSetFilter}
              className={classNames.filters}
            />
          </div>
        ) }
      </ProductSubHeader>

      <div className={classNames.container}>
        <div className={classNames.content}>
          <main>
            { (reviews || [])
              .filter(review => !isSingle() || review.id === match.params.messageId)
              .map(review => (
                <ReviewCardWithRepliesCards
                  key={review.id}
                  review={review}
                  productId={component.id}
                  className={classNames.reviewCard}
                  onClickReply={onClickReply}
                />
              )) }
          </main>
          <aside className={classNames.reviews}>
            <Evaluation
              mark={component.marks}
              filters={filters}
              onSetFilter={onSetFilter}
            />
          </aside>
        </div>
        { !isSingle() && (
          <div className={classNames.loadMore}>
            <LoadMore
              currentCount={currentCount}
              totalCount={totalCount}
              onClickLoadMore={proxyOnLoadPage}
            />
          </div>
        ) }
      </div>
      <CreateReviewFormDialog
        productId={component.id}
        isOpen={isCreateReviewDialogOpen}
        onRequestClose={onCloseCreateReviewDialog}
        onSubmitSuccess={onSubmitReviewSuccess}
      />
    </>
  );
};

FeedbackPage.displayName = 'ReviewPage';

FeedbackPage.propTypes = {
  component: productShape.isRequired,
  reviews: PropTypes.arrayOf(reviewShape),
  onClickReply: PropTypes.func,
  filters: filtersShape,
  onSetFilter: PropTypes.func,
  isCreateReviewDialogOpen: PropTypes.bool,
  onOpenCreateReviewDialog: PropTypes.func,
  onCloseCreateReviewDialog: PropTypes.func,
  onSubmitReviewSuccess: PropTypes.func,
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  onLoadPage: PropTypes.func,
  onSetNumberOfReviewsOnPage: PropTypes.func,
  userPreferences: preferencesShape,
  match: matchShape,
};

FeedbackPage.defaultProps = {
  reviews: [],
  onClickReply: noop,
  filters: {},
  onSetFilter: noop,
  isCreateReviewDialogOpen: false,
  onOpenCreateReviewDialog: noop,
  onCloseCreateReviewDialog: noop,
  onSubmitReviewSuccess: noop,
  currentCount: 0,
  totalCount: 0,
  onLoadPage: noop,
  onSetNumberOfReviewsOnPage: noop,
  userPreferences: {},
  match: {},
};

export default enhancer(FeedbackPage);
