import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';

import ComponentFormPresentationForm from 'react/business/components/form/steps/step-1/PresentationForm';
import ComponentFormStepLayout from '../layout/StepLayout';
import { withComponentFormName } from '../../../../business/components/form/Context';

const enhancer = compose(
  withComponentFormName(),
  // Bind onChangeStep to features tab.
  withHandlers({
    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.FEATURES),
  }),

  memo,
);

const ComponentFormPresentationStep = ({
  onClickNextStep,
  onClickPreviousStep,
  onOpenPreview,
}) => (
  <ComponentFormStepLayout
    stepKey={COMPONENT_FORM_STEPS.PRESENTATION}
    form={<ComponentFormPresentationForm />}
    onClickNextStep={onClickNextStep}
    onClickPreviousStep={onClickPreviousStep}
    onOpenPreview={onOpenPreview}
  />
);

ComponentFormPresentationStep.displayName = 'ComponentFormPresentationStep';

ComponentFormPresentationStep.propTypes = {
  onClickNextStep: PropTypes.func,
  onClickPreviousStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ComponentFormPresentationStep.defaultProps = {
  onClickNextStep: noop,
  onClickPreviousStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ComponentFormPresentationStep);
