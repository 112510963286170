import React from 'react';
import PropTypes from 'prop-types';
import { compose, wrapDisplayName } from 'recompose';

import { Prompt } from 'react-router-dom';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import { withRouter } from 'react-router';
import messages from './form.messages';
import withRouteQueryString from '../../../hoc/withRouteQueryString';

export const withParentProduct = () => compose(
  withRouter,
  withRouteQueryString(
    ({ parent }) => ({ parent }
    ),
  ),
);

export const withPrompt = (WrappedComponent) => {
  const WithPrompt = ({ isDirty, ...props }) => (
    <>
      <FormattedMessageChildren {...messages.UNSAVED_CHANGES}>
        { message => (
          <Prompt
            when={isDirty}
            message={message}
          />
        ) }
      </FormattedMessageChildren>

      <WrappedComponent {...props} />
    </>
  );

  WithPrompt.displayName = wrapDisplayName(WrappedComponent, 'withPrompt');

  WithPrompt.propTypes = {
    isDirty: PropTypes.bool,
  };

  WithPrompt.defaultProps = {
    isDirty: false,
  };

  return WithPrompt;
};

