import { Manifest } from 'services/products/products-form';

import {
  businessUnitInCharge,
  teamMembers,
} from 'react/business/products/form/fields';

export default new Manifest({
  fields: {
    businessUnitInCharge,
    teamMembers,
  },
});
