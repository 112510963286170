import { Manifest } from 'services/products/products-form';

import {
  businessUnitsUsingProduct,
} from 'react/business/products/form/fields';

export default new Manifest({
  fields: {
    businessUnitsUsingProduct,
  },
});
