import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    id: 'views.title.component.editForm',
    defaultMessage: 'Component form ― Tangram',
  },
  ACCESS_DENIED: {
    id: 'views.title.component.editForm.denied',
    defaultMessage: 'Only team members, platform leaders and domain leaders can edit this component.',
  },
});
