import { Manifest } from 'services/products/products-form';

import {
  mainFeatures,
  screenshots,
  modules,
  tagsTechno,
  tagsDevices,
  tagsMisc,
} from 'react/business/products/form/fields';

export default new Manifest({
  fields: {
    mainFeatures,
    screenshots,
    modules,
    tagsTechno,
    tagsDevices,
    tagsMisc,
  },
});
