import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';

import ComponentFormArchitectureBuildingBlocksForm from 'react/business/components/form/steps/step-7/ArchitectureBuildingBlocksForm';
import ComponentFormStepLayout from '../layout/StepLayout';

const enhancer = compose(

  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      COMPONENT_FORM_STEPS.BUSINESS_ACTIVITIES,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.SUBSETS),
  }),

  memo,
);

const ComponentFormArchitectureBuildingBlocks = ({
  onClickPreviousStep,
  onOpenPreview,
  onClickNextStep,
}) => (
  <ComponentFormStepLayout
    stepKey={COMPONENT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS}
    form={<ComponentFormArchitectureBuildingBlocksForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ComponentFormArchitectureBuildingBlocks.displayName = 'ComponentFormArchitectureBuildingBlocks';

ComponentFormArchitectureBuildingBlocks.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ComponentFormArchitectureBuildingBlocks.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ComponentFormArchitectureBuildingBlocks);
