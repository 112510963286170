import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';
import ComponentFormBusinessUnitsForm from 'react/business/components/form/steps/step-4/BusinessUnitsForm';
import ComponentFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.TEAM),
    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.LINKS),
  }),

  memo,
);

const ComponentFormBusinessUnitsStep = ({
  onClickPreviousStep,
  onOpenPreview,
  onClickNextStep,
}) => (
  <ComponentFormStepLayout
    stepKey={COMPONENT_FORM_STEPS.BUSINESS_UNITS}
    form={<ComponentFormBusinessUnitsForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ComponentFormBusinessUnitsStep.displayName = 'ComponentFormBusinessUnitsStep';

ComponentFormBusinessUnitsStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ComponentFormBusinessUnitsStep.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ComponentFormBusinessUnitsStep);
