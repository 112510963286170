import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';

import ComponentFormLinksForm from 'react/business/components/form/steps/step-5/LinksForm';
import ComponentFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    // eslint-disable-next-line max-len
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.BUSINESS_UNITS),
    // eslint-disable-next-line max-len
    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.BUSINESS_ACTIVITIES),
  }),

  memo,
);

const ComponentFormLinksStep = ({
  onClickPreviousStep, onOpenPreview, onClickNextStep,
}) => (
  <ComponentFormStepLayout
    stepKey={COMPONENT_FORM_STEPS.LINKS}
    form={<ComponentFormLinksForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}

  />
);

ComponentFormLinksStep.displayName = 'ComponentFormLinksStep';

ComponentFormLinksStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ComponentFormLinksStep.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ComponentFormLinksStep);
