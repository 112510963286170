import { createSelector } from 'reselect';

export const selectSecurityData = (state, entityId) => state.security?.map[entityId];

export const isLoadingGetSecurityData = state => !!state.security?.isLoading.getSecurityData;
export const isLoadingPatchSecurityData = state => !!state.security?.isLoading.patchSecurityData;

export const isLoadingSecurityData = createSelector(
  [
    isLoadingGetSecurityData,
    isLoadingPatchSecurityData,
  ],
  (...args) => args.some(v => v),
);
