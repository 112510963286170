import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { TimePicker, Tooltip } from 'antd';

import {
  MAX_GITHUB_PREFIX_LENGTH,
  PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_INVOICING_MODEL,
  PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_PERSONAL_DATA,
  PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_PSI,
  PRODUCT_FIELDS_OPTIONS_CIA,
  PRODUCT_FIELDS_OPTIONS_RTO_RPO,
  PRODUCT_FIELDS_SUPPORT_DAYS_AVAILABLE,
} from 'config/constants';

import { SelectWithValueSelector as Select } from 'react/generic/select/Select';
import EditableTagGroup from 'react/generic/tags/EditableTagGroup';
import Input from 'react/generic/form/input/Input';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { formShape } from 'rc-form';
import Wysiwyg from 'react/generic/form/wysiwyg/Wysiwyg';
import classnames from 'classnames';
import messages from './additional-info-form.messages';
import classNames from './additional-info-form.module.scss';
import { LAST_BIA_DATE_COLLIBRA_LINK, NAMING_CONVENTION_LINK, PERSONAL_DATA_COLLIBRA_LINK } from './additional-info-form.constants';

const enhancer = compose(
  memo,
);

const invoicingModelOptions = PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_INVOICING_MODEL.map(
  ({ id, value }) => ({
    label: id ? <FormattedMessage {...messages[id]} /> : '',
    value,
  }),
);

const CreateProductAdditionalInfoForm = ({
  cidcDecorator,
  cidiDecorator,
  ciddDecorator,
  personalDataDecorator,
  psiDecorator,
  aliasesDecorator,
  invoicingModelDecorator,
  githubPrefixDecorator,
  recoveryPointObjectiveRecommendedDecorator,
  recoveryTimeObjectiveRecommendedDecorator,
  lastBIADateDecorator,
  supportTimeRangeStartDecorator,
  supportTimeRangeEndDecorator,
  supportDaysAvailableDecorator,
  recoveryPointObjectiveDecorator,
  recoveryTimeObjectiveDecorator,
  scheduledMaintenanceRangeDecorator,
}) => (
  <div className={classNames.containerInfos}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_ORGANIZATION} />
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_ALIASES} />
      </div>
      <div className={classNames.formField}>
        {
          aliasesDecorator(<EditableTagGroup />)
        }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_INVOICING_MODEL} />
      </div>
      <div className={classNames.formField}>
        {
          invoicingModelDecorator(
            <Select options={invoicingModelOptions} />,
          )
        }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_PREFIX} />
        {' '}
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage
              {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_TOOLTIP}
              values={{
                link: (
                  <a
                    href={NAMING_CONVENTION_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_TOOLTIP_LINK}
                    />
                  </a>
                ),
              }}
            />
            )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          githubPrefixDecorator(
            <Input maxLength={MAX_GITHUB_PREFIX_LENGTH} />,
          )
        }
      </div>
    </div>
    <div className={classNames.title}>
      <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_BUSINESS_IMPACT_ANALYSIS} />
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAC} />
      </div>
      <div className={classNames.formField}>
        {
          cidcDecorator(<Select options={PRODUCT_FIELDS_OPTIONS_CIA} />)
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAI} />
      </div>
      <div className={classNames.formField}>
        {
          cidiDecorator(<Select options={PRODUCT_FIELDS_OPTIONS_CIA} />)
        }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAA} />
      </div>
      <div className={classNames.formField}>
        {
          ciddDecorator(<Select options={PRODUCT_FIELDS_OPTIONS_CIA} />)
        }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA} />
        {' '}
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage
              {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA_TOOLTIP}
              values={{
                link: (
                  <a
                    href={PERSONAL_DATA_COLLIBRA_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA_TOOLTIP_LINK}
                    />
                  </a>
                ),
              }}
            />
            )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          personalDataDecorator(
            <Select options={PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_PERSONAL_DATA} />,
          )
        }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PSI} />
      </div>
      <div className={classNames.formField}>
        {
          psiDecorator(<Select options={PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_PSI} />)
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_TIME_OBJECTIVE_RECOMMENDED} />
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_HOURS} />
          )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          recoveryTimeObjectiveRecommendedDecorator(
            <Select options={PRODUCT_FIELDS_OPTIONS_RTO_RPO} />,
          )
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_POINT_OBJECTIVE_RECOMMENDED} />
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_HOURS} />
          )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          recoveryPointObjectiveRecommendedDecorator(
            <Select options={PRODUCT_FIELDS_OPTIONS_RTO_RPO} />,
          )
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE} />
        {' '}
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage
              {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE_TOOLTIP}
              values={{
                link: (
                  <a
                    href={LAST_BIA_DATE_COLLIBRA_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE_TOOLTIP_LINK}
                    />
                  </a>
                ),
              }}
            />
            )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          lastBIADateDecorator(<Input type="date" />)
        }
      </div>
    </div>
    <div className={classNames.title}>
      <FormattedMessage {...messages.PRODUCT_OPERATIONS} />
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_SUPPORT_HOURS_RANGE} />
      </div>
      <div className={classnames(classNames.formField, classNames.timepickers)}>
        {
          supportTimeRangeStartDecorator(<TimePicker />)
        }
        {
          supportTimeRangeEndDecorator(<TimePicker />)
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_SUPPORT_DAYS_AVAILABLE} />
      </div>
      <div className={classNames.formField}>
        {
          supportDaysAvailableDecorator(
            <Select options={PRODUCT_FIELDS_SUPPORT_DAYS_AVAILABLE} isMulti />,
          )
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_TIME_OBJECTIVE} />
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_HOURS} />
          )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          recoveryTimeObjectiveDecorator(<Select options={PRODUCT_FIELDS_OPTIONS_RTO_RPO} />)
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_POINT_OBJECTIVE} />
        <Tooltip
          placement="top"
          title={(
            <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_HOURS} />
          )}
        >
          <span className={classNames.infoTooltip}>
            <Asset name={ICON_MAP.info} />
          </span>
        </Tooltip>
      </div>
      <div className={classNames.formField}>
        {
          recoveryPointObjectiveDecorator(<Select options={PRODUCT_FIELDS_OPTIONS_RTO_RPO} />)
        }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_SCHEDULED_MAINTENANCE} />
      </div>
      <div className={classNames.formField}>
        {
          scheduledMaintenanceRangeDecorator(<Wysiwyg />)
        }
      </div>
    </div>
  </div>
);
CreateProductAdditionalInfoForm.displayName = 'CreateProductAdditionalInfoForm';

CreateProductAdditionalInfoForm.propTypes = {
  form: formShape.isRequired,
  cidcDecorator: PropTypes.func.isRequired,
  cidiDecorator: PropTypes.func.isRequired,
  ciddDecorator: PropTypes.func.isRequired,
  personalDataDecorator: PropTypes.func.isRequired,
  psiDecorator: PropTypes.func.isRequired,
  aliasesDecorator: PropTypes.func.isRequired,
  invoicingModelDecorator: PropTypes.func.isRequired,
  githubPrefixDecorator: PropTypes.func.isRequired,
  recoveryPointObjectiveRecommendedDecorator: PropTypes.func.isRequired,
  recoveryTimeObjectiveRecommendedDecorator: PropTypes.func.isRequired,
  supportTimeRangeStartDecorator: PropTypes.func.isRequired,
  supportTimeRangeEndDecorator: PropTypes.func.isRequired,
  supportDaysAvailableDecorator: PropTypes.func.isRequired,
  recoveryPointObjectiveDecorator: PropTypes.func.isRequired,
  recoveryTimeObjectiveDecorator: PropTypes.func.isRequired,
  scheduledMaintenanceRangeDecorator: PropTypes.func.isRequired,
  incidentTakingIntoAccountDecorator: PropTypes.func.isRequired,
  urgence1Decorator: PropTypes.func.isRequired,
  urgence2Decorator: PropTypes.func.isRequired,
  urgence3Decorator: PropTypes.func.isRequired,
  takingIntoAccountRequestsDecorator: PropTypes.func.isRequired,
  linkToServiceCatalogUrlDecorator: PropTypes.func.isRequired,
  linkToServiceCatalogTitleDecorator: PropTypes.func.isRequired,
  availabilityDecorator: PropTypes.func.isRequired,
  availabilityInfDecorator: PropTypes.func.isRequired,
  availabilityInfoLinkUrlDecorator: PropTypes.func.isRequired,
  availabilityInfoLinkTitleDecorator: PropTypes.func.isRequired,
  latencyInfoDecorator: PropTypes.func.isRequired,
  latencyInfoLinkUrlDecorator: PropTypes.func.isRequired,
  latencyInfoLinkTitleDecorator: PropTypes.func.isRequired,
  uptimeInfoDecorator: PropTypes.func.isRequired,
  uptimeInfoLinkUrlDecorator: PropTypes.func.isRequired,
  uptimeInfoLinkTitleDecorator: PropTypes.func.isRequired,
  requestedServicesDecorator: PropTypes.func.isRequired,
  lastBIADateDecorator: PropTypes.func.isRequired,
};

export default enhancer(CreateProductAdditionalInfoForm);
