import React, { memo } from 'react';
import { compose } from 'recompose';

import { versionShape } from 'shapes/version';
import SafeHtml from 'react/generic/html/SafeHtml';

import Asset from 'react/generic/asset/Asset';
import { FormattedMessage } from 'react-intl';
import classNames from './version-card.module.scss';
import messages from './version-card.messages';

const enhancer = compose(
  memo,
);

const VersionCard = ({
  version,
}) => (
  <>
    <div className={classNames.versionCard}>
      <div className={classNames.versionHeader}>
        <button className={classNames.versionRelease}>{version.release}</button>
        <h2 className={classNames.versionName}>{version.name}</h2>
        <div className={classNames.versionLinkIcon}>
          <a
            href={version.releaseLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Asset name="link" />
          </a>
        </div>
      </div>
      <div className={classNames.versionBody}>
        <p className={classNames.versionDate}>{new Date(version.date).toLocaleDateString()}</p>
        <p className={classNames.versionDescription}>
          <SafeHtml
            unsafeHtml={version.description}
          />
        </p>
      </div>
      <div className={classNames.versionFooter}>
        <p>
          <FormattedMessage {...messages.VERSION_CREATION_DATE} />
          <span className={classNames.creationDate}>
            {new Date(version.creationDate).toLocaleDateString()}
          </span>
        </p>
        <p>
          <FormattedMessage {...messages.VERSION_CREATED_BY} />
          <span className={classNames.createdBy}>{version.author}</span>
        </p>
      </div>
    </div>
  </>
);

VersionCard.displayName = 'VersionCard';

VersionCard.propTypes = {
  version: versionShape.isRequired,
};

export default enhancer(VersionCard);
