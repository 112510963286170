import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './notification-panel.module.scss';

const enhancer = compose(
  memo,
);

const NotificationPanel = ({ ...props }) => (
  <div
    className={cn(
      props.type === 'info' ? classNames.notificationInformation : '',
      props.type === 'danger' ? classNames.notificationDanger : '',
      props.type === 'warning' ? classNames.notificationWarning : '',
      props.type === 'success' ? classNames.notificationSuccess : '',
      classNames.notification,
      classNames.notificationS,
    )}
  >
    <div className={cn(classNames.notificationContent)}>
      {props.title && (
        <h3 className={cn(classNames.notificationTitle)}>
          {props.title}
        </h3>
      ) }
      {props.message && (
        <div className={cn(classNames.notificationMessage)}>
          {props.message}
        </div>
      )}
    </div>
  </div>
);

NotificationPanel.displayName = 'NotificationPanel';

NotificationPanel.propTypes = {
  type: PropTypes.string,
  title: PropTypes.node,
  message: PropTypes.node,
};

NotificationPanel.defaultProps = {
  type: 'info',
  title: null,
  message: null,
};

export default enhancer(NotificationPanel);
