import React, { memo } from 'react';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router';
import {
  branch,
  compose,
  lifecycle,
  renderNothing,
} from 'recompose';
import connect from 'react/hoc/connectProxy';
import { Helmet } from 'react-helmet';

import ROUTES from 'react/routes';
import { getParentProduct, getProduct, removeProductFromMap } from 'redux/products/actions';
import { getBusinessUnits } from 'redux/businessUnits/actions';
import { selectCanEditProduct, selectDetailedProductById } from 'redux/products/selectors';
import { getCommunities } from 'redux/communities/actions';
import { selectCommunityOfDomain } from 'redux/communities/selectors';

import withStepInRoute from 'react/generic/steps/containers/withValueInRoute';
import withRouteParams from 'react/hoc/withRouteParams';
import { getUsersByExternalIds } from 'redux/users/actions';
import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import userCanAccess from 'react/hoc/canAccess';
import ComponentEditFormPage from 'react/pages/component/form/edit/ComponentEditFormPage';

import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import withSubmitHandlers from '../submit-handlers';

import messages from './component-edit-form.messages';

const enhancer = compose(
  withRouter,

  withRouteParams(params => ({
    componentId: params.componentId,
  })),

  // Step in URL get/set.
  withStepInRoute(
    // Get step from URL.
    ({ match }) => match.params.step,
    // Set step in URL.
    (step, { history, location, componentId }) => {
      const { parent } = queryString.parse(location.search) || {};
      return history.push(
        `${formatRoute(
          ROUTES.COMPONENT.EDIT_FORM,
          {
            componentId,
            step,
          },
        )}?parent=${parent}`,
      );
    },
    // Map value/onChange to step/OnChangeStep
    {
      propsMapper: {
        value: 'step',
        onChange: 'onChangeStep',
      },
      omitRouterProps: false,
    },
  ),

  connect(
    (state, props) => {
      const component = selectDetailedProductById(state, props.componentId);
      return {
        component,
        community: selectCommunityOfDomain(state, component?.domainId),
      };
    },
    {
      getProduct,
      getUsersByExternalIds,
      getCommunities,
      removeProductFromMap,
      getBusinessUnits,
      getParentProduct,
    },
  ),

  withSubmitHandlers,

  lifecycle({
    /**
     * Remove the product from redux -> products -> map
     * to make sure the next time you re-enter the page
     * the latest values are fetched from API
     * and updated properly
     * otherwise old values will be displayed if the page
     * is not refreshed.
     */
    componentWillMount() {
      this.props.removeProductFromMap(this.props.component?.id);
    },

    /** Load product on mount. */
    componentDidMount() {
      this.props.getProduct(this.props.componentId);
      this.props.getCommunities({ populateDomains: true });
      this.props.getBusinessUnits();
      this.props.getParentProduct({ parentDigitalProduct: this.props.parent });
    },
  }),

  // Avoid creating the form if the product isn't ready and the community is not loaded.
  branch(
    ({ component, community }) => !component || !community,
    renderNothing,
  ),

  connect(
    (state, props) => ({
      userCanEditProduct: selectCanEditProduct(state, props.componentId),
    }),
  ),

  userCanAccess(
    props => !!props.userCanEditProduct,
    { errorMessage: <FormattedMessage {...messages.ACCESS_DENIED} /> },
  ),

  // Load users in team members (we need their picture and name).
  withExternalUserLazyLoading({
    getIdsToLoadFromProps: ({ component }) => (component.teamMembers || [])
      .map(tm => tm.externalId)
      .filter(Boolean),
  }),

  memo,
);

const EnhancedComponentEditFormPage = enhancer(ComponentEditFormPage);
EnhancedComponentEditFormPage.displayName = 'EnhancedComponentEditFormPage';

const ComponentEditFormView = () => (
  <>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <EnhancedComponentEditFormPage />
  </>
);
ComponentEditFormView.displayName = 'ComponentEditFormView';

export default ComponentEditFormView;
