import React, { memo } from 'react';
import { Tooltip } from 'antd';
import { compose } from 'recompose';
import { subsetsShape } from '../../../../../shapes/subsets';
import classNames from '../../../architectureBuildingBlocks/list/abb-list.module.scss';
import Asset, { ICON_MAP } from '../../../../generic/asset/Asset';

const enhancer = compose(
  memo,
);

const ProductSubsetItem = ({ item }) => (
  <li className={classNames.item}>
    {item.name}
    {item.description && (
      <Tooltip placement="top" title={item.description}>
        <span className={classNames.infoTooltip}>
          <Asset name={ICON_MAP.info} />
        </span>
      </Tooltip>
    )}
  </li>
);

ProductSubsetItem.displayName = 'ProductSubsetItem';

ProductSubsetItem.propTypes = {
  item: subsetsShape,
};

ProductSubsetItem.defaultProps = {
  item: {},
};

export default enhancer(ProductSubsetItem);
