import { Manifest } from 'services/products/products-form';

import {
  linksProductUrl,
  linksProductMarketingUrl,
  linksRoadmap,
  linksDocumentation,
  linksDiscussion,
  linksApiReference,
  linksSources,
  linksBacklog,
  linksSupport,
} from '../../../../products/form/fields';

export default new Manifest({
  fields: {
    linksProductUrl,
    linksProductMarketingUrl,
    linksRoadmap,
    linksDocumentation,
    linksDiscussion,
    linksApiReference,
    linksSources,
    linksBacklog,
    linksSupport,
  },
});
