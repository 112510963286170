import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'recompose';

import { COMPONENT_FORM_STEPS, makeStepsToDisplay } from 'react/business/components/form/steps/component-form-steps.constants';
import { ComponentFormCurrentStepContext } from 'react/business/components/form/Context';
import { Redirect } from 'react-router';
import ComponentFormPresentationStep from '../step-1/ComponentFormPresentationStep';
import ComponentFormFeaturesStep from '../step-2/ComponentFormFeaturesStep';
import ComponentFormTeamStep from '../step-3/ComponentFormTeamStep';
import ComponentFormBusinessUnitsStep from '../step-4/ComponentFormBusinessUnitsStep';
import ComponentFormLinksStep from '../step-5/ComponentFormLinksStep';
import ComponentFormBusinessActivities from '../step-6/ComponentFormBusinessActivities';
import ComponentFormArchitectureBuildingBlocks from '../step-7/ArchitectureBuildingBlocks';
import ComponentFormSubsets from '../step-8/Subsets';
import ComponentFormValidation from '../step-validation/ComponentFormValidation';

const STEPS_RENDERER = {
  [COMPONENT_FORM_STEPS.PRESENTATION]: ComponentFormPresentationStep,
  [COMPONENT_FORM_STEPS.FEATURES]: ComponentFormFeaturesStep,
  [COMPONENT_FORM_STEPS.TEAM]: ComponentFormTeamStep,
  [COMPONENT_FORM_STEPS.BUSINESS_UNITS]: ComponentFormBusinessUnitsStep,
  [COMPONENT_FORM_STEPS.LINKS]: ComponentFormLinksStep,
  [COMPONENT_FORM_STEPS.BUSINESS_ACTIVITIES]: ComponentFormBusinessActivities,
  [COMPONENT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS]: ComponentFormArchitectureBuildingBlocks,
  [COMPONENT_FORM_STEPS.SUBSETS]: ComponentFormSubsets,
  [COMPONENT_FORM_STEPS.VALIDATION]: ComponentFormValidation,
};

const enhancer = compose(

  withPropsOnChange(
    [],
    () => ({
      steps: makeStepsToDisplay(),
    }),
  ),

  /** Add a state to know if this is the first render or not. */
  withState(
    'hasMounted',
    'onSetHasMounted',
    false,
  ),

  lifecycle({
    /** Did mount. */
    componentDidMount() {
      this.props.onSetHasMounted(true);
    },
  }),

  withHandlers({
    renderStep:
      ({ onChangeStep, onOpenPreview }) => (stepKey) => {
        if (STEPS_RENDERER[stepKey]) {
          return React.createElement(
            STEPS_RENDERER[stepKey],
            {
              key: stepKey,
              onChangeStep,
              onOpenPreview,
            },
          );
        }
        return <Redirect to="/error/404" />;
      },
  }),

  memo,
);

const StepsRenderer = ({
  steps,
  hasMounted,
  renderStep,
}) => (
  // On first render, render all steps to register all fields so we can have all validation errors
  // in the store. On next renders, render only the current step for performance.
  hasMounted ? (
    <ComponentFormCurrentStepContext.Consumer>
      {renderStep}
    </ComponentFormCurrentStepContext.Consumer>
  ) : steps.map(renderStep)
);

StepsRenderer.displayName = 'StepsRenderer';

StepsRenderer.propTypes = {
  hasMounted: PropTypes.bool.isRequired,
  renderStep: PropTypes.func.isRequired,
  /** Keys of steps to display. */
  steps: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COMPONENT_FORM_STEPS))).isRequired,
};

export default enhancer(StepsRenderer);
