import { defineMessages } from 'react-intl';

export default defineMessages({
  PRESENTATION_TITLE: { id: 'component.form.steps.titles.presentation', defaultMessage: 'Step 1' },
  PRESENTATION_SUBTITLE: { id: 'component.form.steps.subtitles.presentation', defaultMessage: 'Presentation' },
  FEATURES_TITLE: { id: 'component.form.steps.titles.features', defaultMessage: 'Step 2' },
  FEATURES_SUBTITLE: { id: 'component.form.steps.subtitles.features', defaultMessage: 'Features' },
  TEAM_TITLE: { id: 'component.form.steps.titles.team', defaultMessage: 'Step 3' },
  TEAM_SUBTITLE: { id: 'component.form.steps.subtitles.team', defaultMessage: 'Team' },
  BUSINESS_UNITS_TITLE: { id: 'component.form.steps.titles.businessUnits', defaultMessage: 'Step 4' },
  BUSINESS_UNITS_SUBTITLE: { id: 'component.form.steps.subtitles.businessUnits', defaultMessage: 'Business Units' },
  LINKS_TITLE: { id: 'component.form.steps.titles.links', defaultMessage: 'Step 5' },
  LINKS_SUBTITLE: { id: 'component.form.steps.subtitles.links', defaultMessage: 'Links' },
  BUSINESS_ACTIVITIES_TITLE: { id: 'component.form.steps.titles.businessActivities', defaultMessage: 'Step 6' },
  BUSINESS_ACTIVITIES_SUBTITLE: { id: 'component.form.steps.subtitles.businessActivities', defaultMessage: 'Business Activities' },
  ARCHITECTURE_BUILDING_BLOCKS_TITLE: { id: 'component.form.steps.titles.abb', defaultMessage: 'Step 7' },
  ARCHITECTURE_BUILDING_BLOCKS_SUBTITLE: { id: 'component.form.steps.subtitles.abb', defaultMessage: 'Architecture' },
  SUBSETS_TITLE: { id: 'component.form.steps.titles.subsets', defaultMessage: 'Step 8' },
  SUBSETS_SUBTITLE: { id: 'component.form.steps.subtitles.subsets', defaultMessage: 'Subsets' },
  VALIDATION_TITLE: { id: 'component.form.steps.titles.validation', defaultMessage: 'Validation' },
});
