import { defineMessages } from 'react-intl';

export default defineMessages({
  PUBLISH: { id: 'component.form.validation.publish', defaultMessage: 'Publish' },
  VALIDATE: { id: 'component.form.validation.validate', defaultMessage: 'Validate' },
  REJECT: { id: 'component.form.validation.reject', defaultMessage: 'Reject' },
  SUBMIT_FOR_DL_VALIDATION: { id: 'component.form.validation.submit.for.dl.validation', defaultMessage: 'Submit for Domain Leader validation' },
  SUBMIT_FOR_CA_VALIDATION: { id: 'component.form.validation.submit.for.ca.validation', defaultMessage: 'Submit for Platform Architect validation' },
  SUBMIT_FOR_BUA_VALIDATION: { id: 'component.form.validation.submit.for.bua.validation', defaultMessage: 'Submit for Business Unit Architect validation' },
  LEAVE: { id: 'component.form.validation.leave', defaultMessage: 'Leave' },

  LAST_STEP_CREATE: {
    id: 'component.form.validation.header.create',
    defaultMessage: 'Your component is ready to be published.',
  },

  LAST_STEP_EDIT: {
    id: 'component.form.validation.header.edit',
    defaultMessage: 'Save your changes',
  },

  CREATE_PUBLISH_NOTICE: {
    id: 'component.form.validation.create.publishNotice',
    defaultMessage: 'You can publish your component now by clicking on "Publish", or come back later and publish it when you want by clicking on "Leave", your draft will be available in your space, in the "My Components" tab.',
  },

  PREVIEW_MESSAGE: {
    id: 'component.form.validation.seePreview',
    defaultMessage: 'You can get a preview on how your component will look like by clicking here: {previewButton}',
  },

  PUBLISH_HINT: {
    id: 'pages.component.form.step.7.publish',
    defaultMessage: "By publishing your digital component, you make it visible to all Tangram users and enrich the ecosystem of the group's digital components.",
  },

  DRAFT_HINT: {
    id: 'pages.component.form.step.7.draft',
    defaultMessage: 'Your digital component has been saved as a draft. Find it in your profile, in the "My products and components" tab.',
  },

  DRAFT_FAILED_HINT: {
    id: 'pages.component.form.step.7.draft.failed',
    defaultMessage: 'Your component could not be saved as a draft. You can try to publish your component instead.',
  },

  SUBMIT_FAILED: {
    id: 'pages.component.form.step.7.submitFailed',
    defaultMessage: 'Your component could not be submitted due to validation errors.',
  },

  RDLV_HEADER_HINT: {
    id: 'component.form.header.hint.RDLV_HEADER_HINT',
    defaultMessage: 'Component requires Domain Leader validation',
  },

  RCA_HEADER_HINT: {
    id: 'component.form.header.hint.RCA_HEADER_HINT',
    defaultMessage: 'Component requires Platform Architect validation',
  },

  RBUA_HEADER_HINT: {
    id: 'component.form.header.hint.RBUA_HEADER_HINT',
    defaultMessage: 'Component requires Business Unit Architect validation',
  },

  PDL_HEADER_HINT: {
    id: 'component.form.header.hint.PDL_HEADER_HINT',
    defaultMessage: 'Component is pending Domain Leader validation',
  },

  PCA_HEADER_HINT: {
    id: 'component.form.header.hint.PCA_HEADER_HINT',
    defaultMessage: 'Component is pending Platform Architect validation',
  },

  PBUA_HEADER_HINT: {
    id: 'component.form.header.hint.PBUA_HEADER_HINT',
    defaultMessage: 'Component is pending Business Unit Architect validation',
  },

  RBDL_HEADER_HINT: {
    id: 'component.form.header.hint.RBDL_HEADER_HINT',
    defaultMessage: 'Component rejected by Domain Leader',
  },

  RBCA_HEADER_HINT: {
    id: 'component.form.header.hint.RBCA_HEADER_HINT',
    defaultMessage: 'Component rejected by Platform Architect',
  },

  RBBUA_HEADER_HINT: {
    id: 'component.form.header.hint.RBBUA_HEADER_HINT',
    defaultMessage: 'Component rejected by Business Unit Architect',
  },

  RDLV_MAIN_HINT: {
    id: 'component.form.header.hint.RDLV_MAIN_HINT',
    defaultMessage: 'Your component must be validated by the Domain Leader before it can be published. Submit for validation.',
  },

  RBUA_MAIN_HINT: {
    id: 'component.form.header.hint.RBUA_MAIN_HINT',
    defaultMessage: 'Your component must be validated by the Business Unit Architect before it can be published. Submit for validation.',
  },

  RCA_MAIN_HINT: {
    id: 'component.form.header.hint.RCA_MAIN_HINT',
    defaultMessage: 'Your component must be validated by the Community Architect before it can be published. Submit for validation.',
  },

  PDL_MAIN_HINT: {
    id: 'component.form.header.hint.PDL_MAIN_HINT',
    defaultMessage: 'Before publishing a component, the Domain Leader must check the information validity.',
  },

  PCA_MAIN_HINT: {
    id: 'component.form.header.hint.PCA_MAIN_HINT',
    defaultMessage: 'Before publishing a component, the Platform Architect must check the information validity.',
  },

  PBUA_MAIN_HINT: {
    id: 'component.form.header.hint.PBUA_MAIN_HINT',
    defaultMessage: 'Before publishing a component, the Business Unit Architect must check the information validity.',
  },

  RBDL_MAIN_HINT: {
    id: 'component.form.header.hint.RBDL_MAIN_HINT',
    defaultMessage: 'The component was rejected by the Domain Leader. Check your email for details and make sure you change the wrong information and then submit again for validation.',
  },

  RBCA_MAIN_HINT: {
    id: 'component.form.header.hint.RBCA_MAIN_HINT',
    defaultMessage: 'The component was rejected by the Platform Architect. Check your email for details and make sure you change the wrong information and then submit again for validation.',
  },

  RBBUA_MAIN_HINT: {
    id: 'component.form.header.hint.RBBUA_MAIN_HINT',
    defaultMessage: 'The component was rejected by the Business Unit Architect. Check your email for details and make sure you change the wrong information and then submit again for validation.',
  },

  INCOMPLETE_COMPONENT: {
    id: 'component.form.header.hint.INCOMPLETE_COMPONENT',
    defaultMessage: 'Please fill in all the required fields first',
  },

  RESEND_VALIDATION_REQUEST: {
    id: 'component.form.header.hint.RESEND_VALIDATION_REQUEST',
    defaultMessage: 'Resend validation request',
  },

  TOAST_VALIDATION: {
    id: 'component.form.validation.toast',
    defaultMessage: 'You validated (componentName).',
  },

  VALIDATION_REQUEST_TIMEOUT: {
    id: 'component.form.validation.validation_request_timeout',
    defaultMessage: 'You will be able to resend your validation request in {hoursLeftUntilResend} hours',
  },
});
