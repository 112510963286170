import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { getFormInitialValues } from 'redux-form';

import connect from 'react/hoc/connectProxy';

import Logo from 'react/business/layout/header/logo/Logo';
import { withComponentFormName, withComponentFormMode } from 'react/business/components/form/Context';
import { FormattedMessage } from 'react-intl';
import { FORM_MODES } from 'react/business/components/form/form.constants';

import cn from 'classnames';
import messages from './header.messages';
import classNames from './header.module.scss';

const resolveTitleByStage = () => (
  <FormattedMessage {...messages.CREATE_COMPONENT} />
);

const enhancer = compose(
  memo,

  withComponentFormMode(),
  withComponentFormName(),

  connect(
    (state, props) => ({
      title: props.formMode === FORM_MODES.UPDATE ?
        getFormInitialValues(props.formName)(state)?.name
        : resolveTitleByStage(),
    }),
  ),
);

const Header = ({
  title,
}) => (
  <header className={cn(classNames.header)}>
    <div className={classNames.logoContainer}>
      <Logo />
    </div>

    <h1 className={classNames.title}>{title}</h1>

  </header>
);

Header.displayName = 'Header';

Header.propTypes = {
  title: PropTypes.node,
};

Header.defaultProps = {
  title: null,
};

export default enhancer(Header);
