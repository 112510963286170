import React, { memo } from 'react';
import { compose } from 'recompose';
import { Tooltip } from 'antd';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { architectureBuildingBlockShape } from 'shapes/architectureBuildingBlocks';

import classNames from '../abb-list.module.scss';

const enhancer = compose(
  memo,
);

const ArchitectureBuildingBlocksListItem = ({
  item,
}) => (
  <li className={classNames.item}>
    {item.name}
    {item.description && (
    <Tooltip placement="top" title={item.description}>
      <span className={classNames.infoTooltip}>
        <Asset name={ICON_MAP.info} />
      </span>
    </Tooltip>
    )}
  </li>
);

ArchitectureBuildingBlocksListItem.displayName = 'ArchitectureBuildingBlocksListItem';

ArchitectureBuildingBlocksListItem.propTypes = {
  item: architectureBuildingBlockShape.isRequired,
};

export default enhancer(ArchitectureBuildingBlocksListItem);
