import { noop } from 'lodash';
import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { suggestionShape, productShape } from 'shapes/product';
import withDialogState from 'react/generic/dialog/withDialogState';
import SuggestionCardWithRepliesCards from 'react/business/suggestion/withReplies/SuggestionCardWithRepliesCards';
import SuggestionFilters from 'react/business/suggestion/filters/SuggestionFilters';
import { filtersShape } from 'react/business/suggestion/suggestions.shapes';
import CreateSuggestionFormDialog from 'react/business/suggestion/form/dialog/create/CreateSuggestionFormDialog';
import ProductSubHeader from 'react/business/products/details/subHeader/ProductSubHeader';
import ContributeButton from 'react/business/products/editButton/ContributeButton';

import withNumberOfItemsOnPage from 'react/generic/filter/withNumberOfItemsOnPage';
import NumberOfItemsOnPageFilter from 'react/generic/filter/NumberOfItemsOnPage';
import { preferencesShape } from 'shapes/preferences';
import { SUGGESTIONS_PAGINATION_ITEM_NUMBER_OPTIONS } from 'config/constants';
import LoadMore from 'react/business/suggestion/pagination/loadMore/LoadMore.presenation';
import { withRouter } from 'react-router';
import ROUTES from 'react/routes';
import { matchShape } from 'shapes/router';

import { withComponentPageContext } from '../../ComponentPage.context';
import withLoadSuggestionsConnect from './withLoadSuggestions.connect';

import messages from './suggestions-page.messages';
import classNames from './suggestions-page.module.scss';

const enhancer = compose(
  withRouter,

  withDialogState('CreateSuggestion'),

  withComponentPageContext(({ component }) => ({ component })),

  // Load suggestions on mount and on filters update.
  withLoadSuggestionsConnect,

  withNumberOfItemsOnPage,

  memo,
);

const SuggestionsPage = ({
  component,
  suggestions,
  onClickReply,
  filters,
  onSetFilter,
  isCreateSuggestionDialogOpen,
  onOpenCreateSuggestionDialog,
  onCloseCreateSuggestionDialog,
  onSubmitSuggestionSuccess,
  currentCount,
  totalCount,
  onLoadPage,
  onSetNumberOfSuggestionsOnPage,
  userPreferences,
  match,
}) => {
  const isSingle = () => match.path === ROUTES.PRODUCT.PRESENTATION_SINGLE
    && match.params.messageId;

  return (
    <>
      <ProductSubHeader>
        <div className={classNames.buttonsContainer}>
          <div>
            <ContributeButton
              onClickEdit={onOpenCreateSuggestionDialog}
              message={<FormattedMessage {...messages.SUGGEST_MY_FEATURE} />}
            />
          </div>
          {!isSingle() && (
          <div>
            <NumberOfItemsOnPageFilter
              onSetNumberOfItems={onSetNumberOfSuggestionsOnPage}
              currentItemsNoValue={userPreferences.suggestionsOnPage}
              options={SUGGESTIONS_PAGINATION_ITEM_NUMBER_OPTIONS}
              messageProps={
                {
                  message: messages.SUGGESTIONS_NO,
                  valuesPlaceholder: 'suggestionsNo',
                }
              }
            />
          </div>
          ) }
        </div>
        {!isSingle() && (
        <div className={classNames.filtersContainer}>
          <SuggestionFilters
            filters={filters}
            onSetFilter={onSetFilter}
            className={classNames.filters}
          />
        </div>
        ) }
      </ProductSubHeader>

      <div className={classNames.container}>
        <div className={classNames.content}>
          <main>
            {suggestions.length === 0
            && (
              <div className={classNames.emptyList}>
                <FormattedMessage {...messages.NO_SUGGESTIONS} />
              </div>
            )}
            {(suggestions || [])
              .filter(suggestion => !isSingle() || suggestion.id === match.params.messageId)
              .map(suggestion => (
                <SuggestionCardWithRepliesCards
                  key={suggestion.id}
                  suggestion={suggestion}
                  productId={component.id}
                  className={classNames.suggestionCard}
                  onClickReply={onClickReply}
                />
              ))}
            {!isSingle() && (
            <LoadMore
              currentCount={currentCount}
              totalCount={totalCount}
              onClickLoadMore={() => onLoadPage(component.id, { preserve: true })}
            />
            ) }
          </main>
        </div>
      </div>
      <CreateSuggestionFormDialog
        productId={component.id}
        isOpen={isCreateSuggestionDialogOpen}
        onRequestClose={onCloseCreateSuggestionDialog}
        onSubmitSuccess={onSubmitSuggestionSuccess}
      />
    </>
  );
};
SuggestionsPage.displayName = 'SuggestionsPage';

SuggestionsPage.propTypes = {
  component: productShape.isRequired,
  suggestions: PropTypes.arrayOf(suggestionShape),
  onClickReply: PropTypes.func,
  filters: filtersShape,
  onSetFilter: PropTypes.func,
  isCreateSuggestionDialogOpen: PropTypes.bool,
  onOpenCreateSuggestionDialog: PropTypes.func,
  onCloseCreateSuggestionDialog: PropTypes.func,
  onSubmitSuggestionSuccess: PropTypes.func,
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  onLoadPage: PropTypes.func,
  onSetNumberOfSuggestionsOnPage: PropTypes.func,
  userPreferences: preferencesShape,
  match: matchShape,
};

SuggestionsPage.defaultProps = {
  suggestions: [],
  onClickReply: noop,
  filters: {},
  onSetFilter: noop,
  isCreateSuggestionDialogOpen: false,
  onOpenCreateSuggestionDialog: noop,
  onCloseCreateSuggestionDialog: noop,
  onSubmitSuggestionSuccess: noop,
  currentCount: 0,
  totalCount: 0,
  onLoadPage: noop,
  onSetNumberOfSuggestionsOnPage: noop,
  userPreferences: {},
  match: {},
};

export default enhancer(SuggestionsPage);
