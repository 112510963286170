import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { productShape } from 'shapes/product';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';

import ArchitectureBuildingBlocksList from 'react/business/architectureBuildingBlocks/list/ArchitectureBuildingBlocksList';

import messages from 'config/business.messages';

const enhancer = compose(
  memo,
);

const ProductArchitectureBuildingBlocks = ({ product }) => (
  <Section>
    <SectionTitle centered>
      <FormattedMessage {...messages.ARCHITECTURE_BUILDING_BLOCKS} />
    </SectionTitle>

    <div>
      <ArchitectureBuildingBlocksList
        abbIds={product.architectureBuildingBlocks}
        communityId={product.communityId}
      />
    </div>
  </Section>
);

ProductArchitectureBuildingBlocks.displayName = 'ProductArchitectureBuildingBlocks';

ProductArchitectureBuildingBlocks.propTypes = {
  product: productShape,
};

ProductArchitectureBuildingBlocks.defaultProps = {
  product: {},
};

export default enhancer(ProductArchitectureBuildingBlocks);
