import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, branch } from 'recompose';
import { noop } from 'lodash';
import memoize from 'memoize-one';

import { ACTIONS as PRODUCT_ACTIONS } from 'redux/products/constants';
import { selectUserLanguage } from 'redux/users/selectors';
import { selectCanEditProduct, selectProductTranslation } from 'redux/products/selectors';
import { getProduct } from 'redux/products/actions';
import { domainShape, communityShape } from 'shapes/community';
import { productShape } from 'shapes/product';
import { isTranslatedContentEmpty } from 'react/generic/translation/translation.utils';
import connect from 'react/hoc/connectProxy';

import TranslationContentSwitch from 'react/generic/translation/TranslationContentSwitch.children';
import ProductHeader from 'react/business/products/header/ProductHeader';
import ComponentTabs from 'react/business/products/tabs/ComponentTabs';
import ProductBanner from 'react/business/products/banner/ProductBanner';
import { securityShape } from 'shapes/security';
import { versionShape } from 'shapes/version';
import ComponentPageTabSwitch from './tabs/ComponentPageTabSwitch';
import { ComponentPageContext } from './ComponentPage.context';

import classNames from './component-page.module.scss';

const withProductLiveTranslation = compose(
  connect(
    (state, props) => ({
      lang: selectUserLanguage(state),
      shouldTranslate: selectUserLanguage(state) !== props.component.contentLanguage,
      productTranslation: selectProductTranslation(
        state,
        props.component.id,
        selectUserLanguage(state),
      ),
      canEditProduct: selectCanEditProduct(state, props.component.id),
    }),

    (dispatch, props) => ({
      onTranslate: lang => dispatch(getProduct(
        props.component.id,
        { lang },
      )),
    }),

    ({ lang, ...stateProps }, dispatchProps, ownProps) => ({
      ...stateProps,
      ...ownProps,
      onTranslate: async () => {
        const { type } = await dispatchProps.onTranslate(lang);
        return {
          success: type === PRODUCT_ACTIONS.GET_PRODUCT_SUCCESS,
        };
      },
    }),
  ),
);

const enhancer = compose(
  branch(
    props => props.component.id,
    withProductLiveTranslation,
  ),
);

class ComponentPage extends PureComponent {
  static propTypes = {
    canEditProduct: PropTypes.bool,
    component: productShape.isRequired,
    domain: domainShape,
    community: communityShape,
    productTranslation: productShape,
    onTranslate: PropTypes.func,
    /** Is the page is preview mode. All actions should be disabled. */
    isPreview: PropTypes.bool,
    view: PropTypes.string,
    security: securityShape,
    versions: PropTypes.arrayOf(PropTypes.shape(versionShape)),
  };

  static defaultProps = {
    canEditProduct: false,
    productTranslation: null,
    domain: null,
    community: null,
    onTranslate: noop,
    isPreview: false,
    view: '',
    security: {},
    versions: [],
  };

  getContextValue = memoize((component, security, canEditProduct, isPreview, versions) => ({
    component,
    isPreview,
    canEditProduct,
    security,
    versions,
  }));

  /** @returns {object} JSX. */
  render() {
    const {
      component: originalProduct,
      domain,
      community,
      productTranslation,
      onTranslate,
      isPreview,
      view,
      canEditProduct,
      security,
      versions,
    } = this.props;

    return (
      <TranslationContentSwitch
        original={originalProduct}
        translated={productTranslation}
        onTranslate={onTranslate}
        isTranslatedContentEmpty={isTranslatedContentEmpty}
      >
        {(component, isTranslatedContentDisplayed, onRequestTranslate) => (
          <ComponentPageContext.Provider
            value={this.getContextValue(
              component,
              security,
              canEditProduct,
              isPreview,
              versions,
            )}
          >
            <div className={classNames.container}>
              <ProductBanner />

              <div className={classNames.productHeader}>
                <div className={classNames.topContainer}>
                  <section className={classNames.header}>
                    <ProductHeader
                      product={component}
                      domain={domain}
                      community={community}
                      isTranslatedContentEmpty={isTranslatedContentEmpty}
                      onRequestTranslate={onRequestTranslate}
                    />
                  </section>

                  <section className={classNames.tabs}>
                    <ComponentTabs
                      component={component}
                      view={view}
                      disabled={isPreview}
                    />
                  </section>
                </div>
              </div>

              <ComponentPageTabSwitch view={view} />
            </div>
          </ComponentPageContext.Provider>
        )}
      </TranslationContentSwitch>
    );
  }
}

export default enhancer(ComponentPage);
