import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose, lifecycle, withHandlers, withPropsOnChange,
} from 'recompose';
import { change, getFormValues } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Steps, { Step } from 'react/generic/steps/Steps';

import { selectDomainsMap } from 'redux/communities/selectors';
import { COMPONENT_FORM_STEPS } from './component-form-steps.constants';
import { withComponentFormName } from '../Context';
import withFormStepManifest from './withFormStepManifest';

import presentationManifest from './step-1/presentation.manifest';
import featuresManifest from './step-2/features.manifest';
import teamManifest from './step-3/team.manifest';
import linksManifest from './step-5/links.manifest';
import businessunitsManifest from './step-4/businessunits.manifest';
import businessActivitiesFormManifest from './step-6/business-activities-form.manifest';
import architectureBuildingBlocksManifest from './step-7/architectureBuildingBlocks.manifest';
import subsetsManifest from './step-8/subsets.manifest';

import messages from './component-form-steps.messages';
import withComponentFromFormValues from '../../../../pages/component/form/withComponentFromFormValues';
import { selectFormParentProduct } from '../../../../../redux/products/selectors';
import { withParentProduct } from '../form.utils';
import { getParentProduct } from '../../../../../redux/products/actions';

const stepEnhancer = manifest => compose(
  withFormStepManifest(
    manifest,
    ({ errorsCount, warningsCount }) => ({
      errorsCount,
      warningsCount,
    }),
  ),

  withHandlers({
    onTouch: ({ onTouch }) => value => onTouch?.(value, manifest),
  }),

  omitProps(['formName', 'currentStep']),
);

const PresentationStep = stepEnhancer(presentationManifest)(Step);
const FeaturesStep = stepEnhancer(featuresManifest)(Step);
const TeamStep = stepEnhancer(teamManifest)(Step);
const BusinessUnitsStep = stepEnhancer(businessunitsManifest)(Step);
const LinksStep = stepEnhancer(linksManifest)(Step);
const BusinessActivitiesStep = stepEnhancer(businessActivitiesFormManifest)(Step);
const ArchitectureBuildingBlocksStep = stepEnhancer(architectureBuildingBlocksManifest)(Step);
const SubsetsStep = stepEnhancer(subsetsManifest)(Step);

const enhancer = compose(
  withComponentFormName(),
  withComponentFromFormValues,
  withParentProduct(),

  connect(
    (state, props) => ({
      isGlobal: getFormValues(props.formName)(state)?.isGlobal === 'true',
      formParentProduct: selectFormParentProduct(state),
      domainsMap: selectDomainsMap(state),
    }),
    (dispatch, props) => ({
      getParentProduct: component => dispatch(getParentProduct(component)),
      changeParentProductField: parentId => dispatch(change(props.formName, 'parentDigitalProduct', parentId)),
      changeDataFromParent: (
        domainId,
        communityId,
        businessUnitInChargeId,
        isMaintainedByPlatform,
      ) => {
        dispatch(change(props.formName, 'domain', domainId));
        dispatch(change(props.formName, 'community', communityId));
        dispatch(change(props.formName, 'businessUnitInCharge', businessUnitInChargeId));
        dispatch(change(props.formName, 'isMaintainedByPlatform', isMaintainedByPlatform));
      },
    }),

  ),

  lifecycle({
    componentWillMount() {
      if (this.props.parent && !this.props.formParentProduct?.id) {
        this.props.getParentProduct(
          { parentDigitalProduct: this.props.parent },
        );
        this.props.changeParentProductField(this.props.parent);
      }
      if (this.props.component?.parentDigitalProduct?.id && !this.props.formParentProduct?.id) {
        this.props.getParentProduct(
          { parentDigitalProduct: this.props.component?.parentDigitalProduct?.id },
        );
        this.props.changeParentProductField(this.props.component?.parentDigitalProduct?.id);
      }
    },
  }),

  withPropsOnChange(
    ['formParentProduct'],
    ({ formParentProduct, domainsMap, changeDataFromParent }) => {
      const domainId = formParentProduct?.domainId;
      const communityId = formParentProduct?.communityId
        || (formParentProduct?.domainId ?
          domainsMap[formParentProduct?.domainId]?.community?.id
          : undefined);
      const businessUnitInCharge = formParentProduct?.businessUnitInCharge;
      const isMaintainedByPlatform = formParentProduct?.isMaintainedByPlatform ? 'true' : 'false';

      changeDataFromParent(
        { value: domainId },
        { value: communityId },
        { value: businessUnitInCharge },
        isMaintainedByPlatform,
      );
    },
  ),

  omitProps(['formName']),

  memo,
);

const ComponentFormSteps = ({
  isGlobal,
  ...props
}) => (
  <Steps {...props}>
    <PresentationStep
      value={COMPONENT_FORM_STEPS.PRESENTATION}
      title={<FormattedMessage {...messages.PRESENTATION_TITLE} />}
      subtitle={<FormattedMessage {...messages.PRESENTATION_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />
    <FeaturesStep
      value={COMPONENT_FORM_STEPS.FEATURES}
      title={<FormattedMessage {...messages.FEATURES_TITLE} />}
      subtitle={<FormattedMessage {...messages.FEATURES_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    <TeamStep
      value={COMPONENT_FORM_STEPS.TEAM}
      title={<FormattedMessage {...messages.TEAM_TITLE} />}
      subtitle={<FormattedMessage {...messages.TEAM_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    {/* Cannot use a Fragment otherwise Steps does not see the Step underneath. */}

    <BusinessUnitsStep
      value={COMPONENT_FORM_STEPS.BUSINESS_UNITS}
      title={<FormattedMessage {...messages.BUSINESS_UNITS_TITLE} />}
      subtitle={<FormattedMessage {...messages.BUSINESS_UNITS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    <LinksStep
      value={COMPONENT_FORM_STEPS.LINKS}
      title={<FormattedMessage {...messages.LINKS_TITLE} />}
      subtitle={<FormattedMessage {...messages.LINKS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    <BusinessActivitiesStep
      value={COMPONENT_FORM_STEPS.BUSINESS_ACTIVITIES}
      title={<FormattedMessage {...messages.BUSINESS_ACTIVITIES_TITLE} />}
      subtitle={<FormattedMessage {...messages.BUSINESS_ACTIVITIES_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    <ArchitectureBuildingBlocksStep
      value={COMPONENT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS}
      title={<FormattedMessage {...messages.ARCHITECTURE_BUILDING_BLOCKS_TITLE} />}
      subtitle={<FormattedMessage {...messages.ARCHITECTURE_BUILDING_BLOCKS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    <SubsetsStep
      value={COMPONENT_FORM_STEPS.SUBSETS}
      title={<FormattedMessage {...messages.SUBSETS_TITLE} />}
      subtitle={<FormattedMessage {...messages.SUBSETS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
      isComponent
    />

    <Step
      value={COMPONENT_FORM_STEPS.VALIDATION}
      title={<FormattedMessage {...messages.VALIDATION_TITLE} />}
      isComponent
    />
  </Steps>
);

ComponentFormSteps.displayName = 'ComponentFormSteps';

ComponentFormSteps.propTypes = {
  isGlobal: PropTypes.bool,
};

ComponentFormSteps.defaultProps = {
  isGlobal: false,
};

export default enhancer(ComponentFormSteps);
