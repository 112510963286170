import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import manifest from './businessunits.manifest';

import messages from './businessunits.messages';

const {
  fields: {
    businessUnitsUsingProduct: { component: BusinessUnitsUsingProductField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const ProductFormBusinessUnitsForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_BU} />}
        message={<FormattedMessage {...messages.BUSINESS_UNITS_HINT} />}
      />

      <BusinessUnitsUsingProductField />
    </FieldsGroup>
  </>
);

export default enhancer(ProductFormBusinessUnitsForm);
