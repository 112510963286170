import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, renameProps } from 'recompose';

import { architectureBuildingBlockShape } from 'shapes/architectureBuildingBlocks';

import ArchitectureBuildingBlocksListItem from './listItem/ArchitectureBuildingBlocksListItem';
import withArchitectureBuildingBlocks from './connect/withArchitectureBuildingBlocks.connect';

import classNames from './abb-list.module.scss';

const enhancer = compose(
  withArchitectureBuildingBlocks,
  renameProps({
    abbs: 'items',
  }),

  memo,
);

const ArchitectureBuildingBlocksList = ({
  items,
}) => (
  <ul className={classNames.list}>
    {items.map(item => (
      <ArchitectureBuildingBlocksListItem
        item={item}
        key={item.id}
      />
    ))}
  </ul>
);

ArchitectureBuildingBlocksList.displayName = 'ArchitectureBuildingBlocksList';

ArchitectureBuildingBlocksList.propTypes = {
  items: PropTypes.arrayOf(architectureBuildingBlockShape),
};

ArchitectureBuildingBlocksList.defaultProps = {
  items: [],
};

export default enhancer(ArchitectureBuildingBlocksList);
