import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { PRODUCT_VIEW } from 'config/constants';

import { withComponentPageContext } from 'react/pages/component/componentPage/ComponentPage.context';

import OverviewPage from './overviewPage/OverviewPage';
import FeedbackPage from './feedbackPage/FeedbackPage';
import SuggestionsPage from './suggestionsPage/SuggestionsPage';
import AdditionalInfoPage from './additionalInfoPage/AdditionalInfoPage';
import VersionsListPage from './versionsPage/VersionsListPage';

const TAB_RENDERERS_MAP = {
  [PRODUCT_VIEW.OVERVIEW]: OverviewPage,
  [PRODUCT_VIEW.REVIEWS]: FeedbackPage,
  [PRODUCT_VIEW.SUGGESTIONS]: SuggestionsPage,
  [PRODUCT_VIEW.ADDITIONAL_INFORMATION]: AdditionalInfoPage,
  [PRODUCT_VIEW.VERSIONS]: VersionsListPage,
};

const enhancer = compose(
  withComponentPageContext(),
  memo,
);

const ComponentPageTabSwitch = ({
  view,
}) => React.createElement(
  TAB_RENDERERS_MAP[view] || OverviewPage,
);

ComponentPageTabSwitch.displayName = 'ComponentPageTabSwitch';

ComponentPageTabSwitch.propTypes = {
  view: PropTypes.string,
};

ComponentPageTabSwitch.defaultProps = {
  view: PRODUCT_VIEW.OVERVIEW,
};

export default enhancer(ComponentPageTabSwitch);
