import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';

import ComponentFormFeaturesForm from 'react/business/components/form/steps/step-2/FeaturesForm';
import ComponentFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      COMPONENT_FORM_STEPS.PRESENTATION,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(COMPONENT_FORM_STEPS.TEAM),
  }),

  memo,
);

const ComponentFormFeaturesStep = ({ onClickPreviousStep, onOpenPreview, onClickNextStep }) => (
  <ComponentFormStepLayout
    stepKey={COMPONENT_FORM_STEPS.FEATURES}
    form={<ComponentFormFeaturesForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ComponentFormFeaturesStep.displayName = 'ComponentFormFeaturesStep';

ComponentFormFeaturesStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
  onClickNextStep: PropTypes.func,
};

ComponentFormFeaturesStep.defaultProps = {
  onClickPreviousStep: noop,
  onOpenPreview: noop,
  onClickNextStep: noop,
};

export default enhancer(ComponentFormFeaturesStep);
