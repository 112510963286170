import React, { memo } from 'react';
import VersionCardList from 'react/business/versions/list/VersionCardList';
import { compose } from 'recompose';
import { withComponentPageContext } from '../../ComponentPage.context';

const enhancer = compose(
  withComponentPageContext(({ component, versions }) => (
    { component, versions }
  )),

  memo,
);
const VersionsPageList = ({ component, versions }) => (

  <>
    <VersionCardList
      versions={versions}
      productId={component.id}
    />
  </>
);

export default enhancer(VersionsPageList);
