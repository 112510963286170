import { withPropsOnChange } from 'recompose';
import { buildTreeFromAtomicActivities } from 'services/businessActivities/functions';

export default withPropsOnChange(
  [
    'value',
    'activitiesMap',
    'atomicActivitiesMap',
  ],
  ({
    value,
    activitiesMap,
    atomicActivitiesMap,
  }) => ({
    tree: buildTreeFromAtomicActivities(value, { atomicActivitiesMap, activitiesMap }),
  }),
);
